import classNames from "classnames";
import React from "react";
import { useAuthPageContent } from "./hooks";

interface AuthFooterProps {
  toSignUp: boolean;
  onButtonClick: () => void;
  hideMainButton?: boolean;
  isFormOpened?: boolean;
  buttonStyleOverwrite?: string;
  textOverwrite?: string;
}

const AuthFooter: React.FC<AuthFooterProps> = ({
  toSignUp = false,
  hideMainButton = false,
  isFormOpened,
  buttonStyleOverwrite,
  onButtonClick,
  textOverwrite,
}) => {
  const { handleActionClick, content } = useAuthPageContent(toSignUp);

  return (
    <div className="auth-footer">
      {
        <button
          onClick={onButtonClick}
          className={classNames(
            "button",
            "auth-btn",
            "auth-mobile-only",
            !toSignUp && "auth-btn-accent",
            buttonStyleOverwrite
          )}
        >
          {textOverwrite ? textOverwrite : content.button}
        </button>
      }
      <span className={classNames("auth-footer-question")}>
        {content.question}
        <span onClick={handleActionClick} className="auth-footer-action">
          {" "}
          {content.action}
        </span>
      </span>
      {!isFormOpened && (
        <div className={classNames("auth-desktop-only", "auth-footer-logo")} />
      )}
    </div>
  );
};

export default React.memo(AuthFooter);
