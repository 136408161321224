export const activityOptions = [
  { value: "Running/Jogging", label: "Running/Jogging" },
  { value: "Swimming", label: "Swimming" },
  { value: "Cycling", label: "Cycling" },
  { value: "Weightlifting", label: "Weightlifting" },
  { value: "Yoga", label: "Yoga" },
  { value: "Pilates", label: "Pilates" },
  { value: "Dancing", label: "Dancing (e.g., Ballet, Hip Hop, Salsa)" },
  { value: "Soccer/Football", label: "Soccer/Football" },
  { value: "Basketball", label: "Basketball" },
  { value: "Tennis", label: "Tennis" },
  { value: "Volleyball", label: "Volleyball" },
  { value: "Golf", label: "Golf" },
  {
    value: "Martial Arts",
    label: "Martial Arts (e.g., Karate, Judo, Taekwondo)",
  },
  { value: "Hiking", label: "Hiking" },
  { value: "Rock Climbing", label: "Rock Climbing" },
  { value: "CrossFit", label: "CrossFit" },
  { value: "Rowing", label: "Rowing" },
  { value: "Skiing/Snowboarding", label: "Skiing/Snowboarding" },
  { value: "Surfing", label: "Surfing" },
  { value: "Skateboarding", label: "Skateboarding" },
  { value: "Boxing", label: "Boxing" },
  { value: "Kickboxing", label: "Kickboxing" },
  { value: "Zumba", label: "Zumba" },
  { value: "Aerobics", label: "Aerobics" },
  { value: "Cross-country Skiing", label: "Cross-country Skiing" },
  { value: "Water Aerobics", label: "Water Aerobics" },
  { value: "Ultimate Frisbee", label: "Ultimate Frisbee" },
  { value: "Cricket", label: "Cricket" },
  { value: "Rugby", label: "Rugby" },
  { value: "Horseback Riding", label: "Horseback Riding" },
  { value: "Gymnastics", label: "Gymnastics" },
  {
    value: "Rollerblading/Roller Skating",
    label: "Rollerblading/Roller Skating",
  },
  { value: "Table Tennis", label: "Table Tennis" },
  { value: "Badminton", label: "Badminton" },
  { value: "Archery", label: "Archery" },
  { value: "Canoeing/Kayaking", label: "Canoeing/Kayaking" },
  { value: "Fencing", label: "Fencing" },
  { value: "Synchronized Swimming", label: "Synchronized Swimming" },
  { value: "Powerlifting", label: "Powerlifting" },
  { value: "Taichi/Qigong", label: "Taichi/Qigong" },
  { value: "Other", label: "Other" },
];

export const improvementOptions = [
  { value: "bodyFatPercentage", label: "Body fat %" },
  { value: "Strength", label: "Strength" },
  { value: "Power", label: "Power" },
  { value: "Endurance", label: "Endurance" },
  { value: "None", label: "None" },
];

export const sufferFromOptions = [
  { value: "Stress", label: "Stress" },
  { value: "lowMood", label: "Low Mood" },
  { value: "Anxiety", label: "Anxiety" },
  { value: "Worry", label: "Worry" },
  { value: "None", label: "None" },
];
