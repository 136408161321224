import { createTheme } from "@mui/material/styles";

// Extend the Palette interface
declare module "@mui/material/styles" {
  interface Palette {
    blueProgress: Palette["primary"];
    greenComplete: Palette["primary"];
  }

  interface PaletteOptions {
    blueProgress?: PaletteOptions["primary"];
    greenComplete?: PaletteOptions["primary"];
  }
}

// Now create the theme with the extended palette
export const basicTheme = createTheme({
  palette: {
    blueProgress: {
      main: "#2196f3",
    },
    greenComplete: {
      main: "#4caf50",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "330px",
          "& label.Mui-focused": {
            color: "#9940F6",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-3px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#fff",
          borderRadius: 8,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9940F6",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9940F6",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        // Apply styles only to inputs, excluding textarea
        root: {
          "&.MuiOutlinedInput-root": {
            padding: "14px 16px 11px 11px",
          },

          "& input": {
            padding: "0",
          },
          "& textarea": {
            padding: "0",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          background: "none",
          "&:hover": {
            background: "none",
          },
        },
      },
    },
  },
});

export const editTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "330px",
          "& label.Mui-focused": {
            color: "#9390F9",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-3px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#fff",
          borderRadius: 8,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9390F9",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9390F9",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        // Apply styles only to inputs, excluding textarea
        root: {
          "&.MuiOutlinedInput-root": {
            padding: "14px 16px 11px 11px",
          },

          "& input": {
            padding: "0",
          },
          "& textarea": {
            padding: "0",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          background: "none",
          "&:hover": {
            background: "none",
          },
        },
      },
    },
  },
});
