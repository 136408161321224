// PrivateRoutes.js
import { Outlet, Navigate } from "react-router-dom";
import { useCurrentUser } from "../Auth/UseCurrentUser";
import MainLayout from "../MainLayout/MainLayout"; // Ensure path is correct
// import { currentUser } from "../Cards/mockData";

const PrivateRoutes = () => {
  //TODO - UNCOMMENT @MARINA
  const { isLoading, error, data: currentUser } = useCurrentUser();
  const auth = currentUser?.authenticated;

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return auth ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/sign-in" replace />
  );
};

export default PrivateRoutes;
