import React from "react";
//ASSETS
import "./navbar.css";
import NavBarDesktop from "./NavBarDesktop";
import useResponsive from "../../hooks/useResponsive";
import NavBarMobile from "./NavBarMobile";

const NavBar: React.FC = () => {
  const { isDesktop } = useResponsive();

  if (isDesktop) {
    return <NavBarDesktop />;
  }

  if (!isDesktop) {
    return <NavBarMobile />;
  }
};

export default NavBar;
