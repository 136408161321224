import { useLocation } from "react-router-dom";

export const useIsPage = (path = "") => {
  const { pathname } = useLocation();

  const isHome = pathname === "/";
  const isHowToRead = pathname === "/how_to_read";
  const isQuestionnaire = pathname === "/questionnaire";
  const isDashboard = pathname === "/dashboard";
  const isAdmin = pathname === "/admin";
  const isSettings = pathname === "/settings";
  const isCustomLocation = pathname === path;

  return {
    isDashboard,
    isHowToRead,
    isQuestionnaire,
    isCustomLocation,
    isAdmin,
    isSettings,
    isHome,
  };
};
