import { useQuery } from "react-query";
import config from "../../config";
import axios from "axios";

export const useCurrentUser = () => {
  return useQuery(
    "currentUser",
    async () => {
      const { data } = await axios.get(`${config.apiBaseURL}/auth/user`, {
        withCredentials: true,
      });
      const userData = {
        authenticated: true,
        user: data,
      };
      return userData;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchInterval: (data) => {
        if (!data?.authenticated || !data?.user?.has_confirmed_how_to_read) {
          return 0;
        }
        return data?.user?.has_report ? 0 : 30000;
      },
    }
  );
};
