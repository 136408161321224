import classNames from "classnames";
import React from "react";

interface ExpandButtonProps {
  isHidden: boolean;
  onClick?: () => void;
  arrowChangesColor?: boolean;
}

const ExpandButton: React.FC<ExpandButtonProps> = ({
  onClick,
  isHidden,
  arrowChangesColor,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        arrowChangesColor && "expandBtn-two-colors",
        "expandBtn",
        !isHidden && "expandBtn_rotated"
      )}
    />
  );
};

export default ExpandButton;
