import { FC, useState } from "react";
import { VitaminItemType } from "./VitaminsList";
import ExpandButton from "../ExpandButton/ExpandButton";
import classNames from "classnames";

interface Props {
  vitamin: VitaminItemType;
}

const VitaminItem: FC<Props> = ({ vitamin }) => {
  const [isExpended, setIsExpended] = useState(false);

  return (
    <div className="vitaminsListItemWrap">
      <div
        onClick={() => setIsExpended(!isExpended)}
        className="vitaminsListItem"
        key={vitamin.name}
      >
        <ExpandButton
          isHidden={!isExpended}
          onClick={() => setIsExpended(!isExpended)}
        />
        <span
          className={classNames(
            isExpended && "isExpended",
            "vitaminsListItemName"
          )}
        >
          {vitamin.name}
        </span>
        <span className="vitaminsListItemQuantity">{`${vitamin.dosage} ${vitamin.measurement_unit}`}</span>
      </div>
      {isExpended && (
        <div className="vitaminsListItemJustification">
          {vitamin.justification}
        </div>
      )}
    </div>
  );
};

export default VitaminItem;
