import classNames from "classnames";
import React from "react";
import { useAuthPageContent } from "./hooks";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";

interface AuthHeaderProps {
  toSignUp: boolean;
}

const AuthHeader: React.FC<AuthHeaderProps> = ({ toSignUp }) => {
  const { handleActionClick, content } = useAuthPageContent(toSignUp);

  return (
    <div className={classNames("auth-header-wrap", "auth-desktop-only")}>
      <div className="sign-up-logo-desktop" />
      <ButtonWithIcon onClick={handleActionClick} text={content.action} />
    </div>
  );
};

export default React.memo(AuthHeader);
