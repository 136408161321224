import { TextField, ThemeProvider, Snackbar, Alert } from "@mui/material";
import classNames from "classnames";
import { Field, Form, Formik, FormikProps } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { useMutation } from "react-query";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";
import useResponsive from "../../hooks/useResponsive";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import { basicTheme } from "../../helpers/themes";
import config from "../../config";

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  new_password: string;
  confirm_password: string;
}

const Settings = () => {
  const { data: currentUser } = useCurrentUser();
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const { isMobile, isDesktop } = useResponsive();

  const initialValues: FormValues = {
    first_name: currentUser?.user?.first_name || "",
    last_name: currentUser?.user?.last_name || "",
    email: currentUser?.user?.email || "",
    password: "",
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("Please enter first name")
      .min(2, "Name too short"),
    last_name: Yup.string()
      .required("Please enter last name")
      .min(2, "Name too short"),
    email: Yup.string().required("Please enter email").email("Invalid email"),
    password: showPasswordFields
      ? Yup.string()
          .required("Please enter your current password")
          .min(7, "Password should be minimum 7 characters long")
      : Yup.string(),
    new_password: showPasswordFields
      ? Yup.string()
          .required("Please enter a new password")
          .min(7, "New password should be minimum 7 characters long")
      : Yup.string(),
    confirm_password: showPasswordFields
      ? Yup.string()
          .required("Please confirm your new password")
          .oneOf([Yup.ref("new_password"), ""], "Passwords must match")
      : Yup.string(),
  });

  const editFormMutation = useMutation((values: Partial<FormValues>) => {
    return axios.put(`${config.apiBaseURL}/auth/user`, values, {
      withCredentials: true,
    });
  });

  const getChangedFields = (
    currentValues: FormValues,
    initialValues: FormValues
  ) => {
    const changedFields: Partial<FormValues> = {};
    (Object.keys(currentValues) as (keyof FormValues)[]).forEach((key) => {
      if (currentValues[key] !== initialValues[key]) {
        changedFields[key] = currentValues[key];
      }
    });
    return changedFields;
  };

  const handleEditForm = async (values: FormValues) => {
    const changedFields = getChangedFields(values, initialValues);
    if (Object.keys(changedFields).length === 0) {
      setErrorMessage("No changes made.");
      setErrorSnackbarOpen(true);
      return;
    }

    try {
      await editFormMutation.mutateAsync(changedFields);
      setIsSuccess(true);
      setErrorMessage("Successfully updated!");
      setErrorSnackbarOpen(true);
    } catch (error) {
      setIsSuccess(false);
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(
          error.response.data?.message ||
            "An error occurred. Please try again later."
        );
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
      setErrorSnackbarOpen(true);
      console.log("error: ", error);
    }
  };

  return (
    <ThemeProvider theme={basicTheme}>
      <div className={classNames("LeftDiv", "settings-wrap")}>
        <h2 className="basic-page-header">Settings</h2>
        <div
          className={classNames("settings-form-wrap", "basic-section", {
            "mobile-class": isMobile,
          })}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleEditForm}
          >
            {({ errors, touched, values, resetForm, submitForm }) => (
              <Form
                onChange={() => {
                  if (!hasChanged) {
                    setHasChanged(true);
                  }
                }}
              >
                <label className="settings-form-first-label">First Name</label>
                <Field
                  name="first_name"
                  as={TextField}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  InputProps={{
                    sx: { color: "#8E95A9" },
                  }}
                  error={Boolean(errors.first_name && touched.first_name)}
                  helperText={touched.first_name && errors.first_name}
                />
                <label>Last Name</label>
                <Field
                  name="last_name"
                  as={TextField}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  InputProps={{
                    sx: { color: "#8E95A9" },
                  }}
                  error={Boolean(errors.last_name && touched.last_name)}
                  helperText={touched.last_name && errors.last_name}
                />
                <label>Email</label>
                <Field
                  name="email"
                  as={TextField}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  InputProps={{
                    sx: { color: "#8E95A9" },
                  }}
                  error={Boolean(errors.email && touched.email)}
                  helperText={touched.email && errors.email}
                />
                <div className="settings-divider" />
                <span
                  className="settings-form-question"
                  onClick={() => setShowPasswordFields(!showPasswordFields)}
                >
                  Change password?
                </span>
                {showPasswordFields && (
                  <>
                    <label>Old Password</label>
                    <Field
                      name="password"
                      type="password"
                      as={TextField}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      InputProps={{
                        sx: { color: "#8E95A9" },
                      }}
                      error={Boolean(errors.password && touched.password)}
                      helperText={touched.password && errors.password}
                    />
                    <label>New Password</label>
                    <Field
                      name="new_password"
                      type="password"
                      as={TextField}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      InputProps={{
                        sx: { color: "#8E95A9" },
                      }}
                      error={Boolean(
                        errors.new_password && touched.new_password
                      )}
                      helperText={touched.new_password && errors.new_password}
                    />
                    <label>Confirm New Password</label>
                    <Field
                      name="confirm_password"
                      type="password"
                      as={TextField}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      InputProps={{
                        sx: { color: "#8E95A9" },
                      }}
                      error={Boolean(
                        errors.confirm_password && touched.confirm_password
                      )}
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                    />
                  </>
                )}
                <footer
                  className="settings-footer"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  {/* <ButtonWithIcon
                    classNameOverWrite="save-btn"
                    classNameIconOverWrite="save-btn-icon"
                    text="Cancel"
                    onClick={() => {
                      resetForm();
                      setHasChanged(false);
                    }}
                    disabled={!hasChanged}
                  /> */}
                  <ButtonWithIcon
                    classNameOverWrite="save-btn"
                    classNameIconOverWrite="save-btn-icon"
                    text="Save Changes"
                    onClick={submitForm}
                    disabled={!hasChanged}
                  />
                </footer>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setErrorSnackbarOpen(false)}
          severity={isSuccess ? "success" : "error"}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Settings;
