import { useEffect, useRef } from "react";

const useCloseTogglable = (exclusionRefs: any, toggleHidden: () => void) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isExcluded = exclusionRefs.some((exclusionRef: any) =>
        exclusionRef.current?.contains(event.target as Node)
      );

      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !isExcluded
      ) {
        toggleHidden();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return ref;
};

export default useCloseTogglable;
