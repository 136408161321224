import classNames from "classnames";
import React, { useContext } from "react";
import useResponsive from "../../hooks/useResponsive";
import { Context } from "../../helpers/Context";

interface GeneTableProps {
  gene: {
    explanation: string;
    name: string;
    mainFunction: string;
    id: string;
    genotype: string;
  };
}

const DemonstrationTinyTable: React.FC<GeneTableProps> = ({
  gene: {
    explanation = "",
    name = "SOD2",
    mainFunction = "Superoxide dismutase",
    id = "rs4880",
    genotype = "AG",
  },
}) => (
  <div className={classNames("score-calc-table", "score-calc-card")}>
    <div>
      <div className="snp-title">
        <div className="gene-container">
          <span className="label">GENE</span>
          <span className="gene">{mainFunction}</span>
        </div>
        <div className="rs-genotype-container">
          <div className="rs-container">
            <span className="label">RS ID</span>
            <span className="rs-id">{id}</span>
          </div>
          <div className="genotype-container">
            <span className="label">GENOTYPE</span>
            <span className="genotype">{genotype}</span>
          </div>
        </div>
      </div>
      <p className="snp-overview">{explanation}</p>
    </div>
  </div>
);

const GeneTable: React.FC<GeneTableProps> = ({
  gene: {
    explanation = "The score shown is derived from the combination of all your genetic markers that we tested. Each genes influence on the overall risk score is weighted based on scientific evidence available for each variant. For instance, if you carry a risk variant in 3 out of 5 genes, you might receive a score indicating a moderate risk. This helps in understanding your predisposition and potential health outcomes.",
    name = "SOD2",
    mainFunction = "Superoxide dismutase",
    id = "rs4880",
    genotype = "AG",
  },
}) => {
  const { isExpandedInfoHidden } = useContext(Context);

  const { isMobile } = useResponsive();

  if (isMobile) {
    return <></>;
  }

  return (
    <table
      className={classNames(
        "score-calc-table",
        isExpandedInfoHidden && "score-calc-table-fixed"
      )}
    >
      <tbody className="score-calc-table-body">
        <tr>
          <th>The gene we tested for</th>
          <th>It’s primary functions</th>
          <th className="score-calc-table-align-right">
            The location on the genome
          </th>
          <th className="score-calc-table-align-right">Your test result</th>
        </tr>
        <tr>
          <td>
            <div
              className={classNames(
                "score-calc-table-arrow-left",
                "score-calc-table-arrow",
                "needs-margin"
              )}
            />
          </td>
          <td>
            <div
              className={classNames(
                "score-calc-table-arrow-left",
                "score-calc-table-arrow"
              )}
            />
          </td>
          <td>
            <div
              className={classNames(
                "score-calc-table-arrow-right",
                "score-calc-table-arrow",
                "score-calc-table-align-right"
              )}
            />
          </td>
          <td>
            <div
              className={classNames(
                "score-calc-table-arrow-right",
                "score-calc-table-arrow",
                "score-calc-table-align-right"
              )}
            />
          </td>
        </tr>
        {/* in real app will come from props inside spans */}
        <tr className="score-calc-table-gene-row">
          <td>
            GENE <span className="score-calc-table-gene-span">{name}</span>
          </td>
          <td>
            <span
              className={classNames("score-calc-table-gene-span", "no-margin")}
            >
              {mainFunction}
            </span>
          </td>
          <td className="score-calc-table-align-right">
            RD ID <span className="score-calc-table-gene-info-span">{id}</span>
          </td>
          <td className="score-calc-table-align-right">
            GENOTYPE{" "}
            <span className="score-calc-table-gene-info-span">{genotype}</span>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>{explanation}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default GeneTable;
