// MainLayout.js
import React, { useRef, useCallback } from "react";
import NavBar from "../Navbar/NavBar";
import SideNavigation from "../SideNavigation/SideNavigation";
import ExpandableInfo from "../ExpandableInfo/ExpandableInfo";
import MobileFixedFooter from "../MobileFixedFooter/MobileFixedFooter";
import PersonalSupplement from "../PersonalSupplement/PersonalSupplement";
import useResponsive from "../../hooks/useResponsive";

//ASSETS
import "./mainlayout.css";

const MainLayout = ({ children }) => {
  const { isDesktop } = useResponsive();
  const topOfPageRef = useRef(null);

  const scrollToTop = useCallback(() => {
    topOfPageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      {isDesktop && <SideNavigation />}
      <div className="MainContent">
        <NavBar />
        <div ref={topOfPageRef} className="Content">
          {children}
          {isDesktop && (
            <ExpandableInfo>
              <PersonalSupplement header={null} />
            </ExpandableInfo>
          )}
        </div>
        <MobileFixedFooter onScrollToTop={scrollToTop} />
      </div>
    </>
  );
};

export default MainLayout;
