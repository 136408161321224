import React, { useContext } from "react";
import { Context } from "../../helpers/Context";
import Footer from "../ExpandableInfo/Footer";
import ExpandableSection from "../ExpandableSection/ExpandableSection";
import VitaminsList from "../VitaminsList/VitaminsList";
import { promoPage } from "../../helpers/variables";
//@ts-ignore
import supplementsBottle from "../../assets/supplement-bottle.jpg";
//@ts-ignore
import lockedScreenIcon from "../../assets/locked-screen.svg";
import classNames from "classnames";
import { useCurrentUser } from "../Auth/UseCurrentUser";

const LockedScreen = () => (
  <div className="personal-supplement-locked-screen">
    <img alt="locked-screen" src={lockedScreenIcon} />
  </div>
);

interface PersonalSupplementProps {
  header: React.ReactNode;
  onClosePersonalSupplement?: () => void;
}

const formatDate = (value: string) => {
  if (!value) return "-";
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}/${month}/${year}`;
};

const PersonalSupplement: React.FC<PersonalSupplementProps> = ({
  header,
  onClosePersonalSupplement,
}) => {
  const mockUserContext = {
    subscribed: true,
  };

  const { report } = useContext(Context);
  const { data: currentUser } = useCurrentUser();
  const hasSubscriptionPlan = currentUser?.user?.subscription_plan_id || false;
  const subscriptionEndDate = formatDate(
    currentUser?.user?.subscription_end_date
  );
  const products = report.products || [];
  return (
    <section
      className={classNames(
        "personal-supplement-wrap",
        "score-calc-info-section"
      )}
    >
      <div
        onClick={onClosePersonalSupplement}
        className="personal-supplement-cross"
      />
      {header}
      <div
        className={classNames(
          header && "personal-supplement-lowered",
          "basic-page-header"
        )}
      >
        Your Personalised Supplement Formula
      </div>
      {!products && <LockedScreen />}
      <div className="report-info-container">
        <div className="report-info-header">
          <h3 className="personal-supplement-title">What is this section?</h3>
          <div className="score-calc-description">
            {mockUserContext.subscribed
              ? "Based on your survey data and genetic sequencing information, our algorithm has developed an entirely unique supplement product to you. This bespoke supplement contains only ingredients and compounds that are exactly aligned with your biology (with respect to dosages and products) and only those have been clinically validated through rigorous (and replicated) scientific research to have a significant affect on physical and mental and performance. Click on each ingredient to see a summary of why it has been included and what effect it is designed to provide. Once you are happy with your supplement, hit ‘order’ and we will ship you out a 3-month supply to get you performing to your potential!"
              : promoPage.text}
          </div>
        </div>
        {mockUserContext.subscribed ? (
          <VitaminsList isHideButton={true} vitaminsList={products} />
        ) : (
          <img alt="vitamins" src={supplementsBottle} />
        )}
        <Footer
          helpButtonText={
            mockUserContext.subscribed
              ? "Edit my formula"
              : promoPage.actionBtn1
          }
          actionButtonText={
            hasSubscriptionPlan
              ? `Ordered until ${subscriptionEndDate}`
              : "Order now"
          }
          actionButtonDisabled={hasSubscriptionPlan}
          products={products}
        />
      </div>
    </section>
  );
};

export default PersonalSupplement;
