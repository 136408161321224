export const contentRegister = {
  button: "Create account",
  question: "Have an account?",
  action: "Log in",
  actionLink: "/sign-in",
};

export const contentLogin = {
  button: "Log in",
  question: "Don't have an account?",
  action: "Sign up",
  actionLink: "/sign-up",
};

export const alternativeBackgrounds = [
  "auth-alternative-bg-0",
  "auth-alternative-bg-1",
  "auth-alternative-bg-2",
  "auth-alternative-bg-3",
  "auth-alternative-bg-4",
];
