import AdminTable from "../../components/admin/AdminTable";
import classNames from "classnames";

const Admin = () => {
  return (
    <div className={classNames("LeftDiv", "admin-wrap")}>
      <h2 className="basic-page-header">Admin page</h2>
      <AdminTable />
    </div>
  );
};

export default Admin;
