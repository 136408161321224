import React from "react";
import "./admin.css";

const AdminQuestionnaire = ({ data }) => {
  if (!data) {
    return <p>No user response available</p>;
  }
  const renderGeneralQuestions = () => {
    const { generalQuestions } = data;
    return (
      <div className="user-profile-section">
        <h2>General Questions</h2>
        <p>Date of Birth: {generalQuestions.date_of_birth}</p>
        <p>Ethnicity: {generalQuestions.ethnicity}</p>
        <p>Gender: {generalQuestions.gender}</p>
        <p>Height: {generalQuestions.height}</p>
        <p>Origin: {generalQuestions.origin.join(", ")}</p>
        <p>Weight: {generalQuestions.weight}</p>
      </div>
    );
  };

  const renderNutrition = () => {
    const { nutrition } = data;
    return (
      <div className="user-profile-section">
        <h2>Nutrition</h2>
        <p>Allergies or Intolerances: {nutrition.allergiesOrIntolerances}</p>
        <p>
          Allergies or Intolerances Type:{" "}
          {nutrition.allergiesOrIntolerancesType}
        </p>
        <p>Breakfast: {nutrition.breakfast}</p>
        <p>Caffeinated Drinks: {nutrition.caffeinatedDrinks}</p>
        <p>Caloric Intake: {nutrition.caloricIntake}</p>
        <p>Dietary Considerations: {nutrition.dietaryConsiderations}</p>
        <p>Dinner: {nutrition.dinner}</p>
        <p>Lunch: {nutrition.lunch}</p>
        <p>Personalized Nutrition: {nutrition.personalizedNutrition}</p>
        <p>Snacks: {nutrition.snacks}</p>
        <p>Supplements: {nutrition.supplements}</p>
        <p>Supplements Description: {nutrition.supplementsDescription}</p>
        <p>Weight Goal: {nutrition.weightGoal}</p>
      </div>
    );
  };

  const renderSleepAndIllness = () => {
    const { sleepAndIllness } = data;
    return (
      <div className="user-profile-section">
        <h2>Sleep and Illness</h2>
        <p>Antibiotics Frequency: {sleepAndIllness.antibioticsFrequency}</p>
        <p>
          Common Illness Frequency: {sleepAndIllness.commonIllnessFrequency}
        </p>
        <p>
          Common Illness Recovery Time:{" "}
          {sleepAndIllness.commonIllnessRecoveryTime}
        </p>
        <p>Pre-existing Conditions: {sleepAndIllness.preExistingConditions}</p>
        <p>Prescription Medicine: {sleepAndIllness.prescriptionMedicine}</p>
        <p>Sleep Hours: {sleepAndIllness.sleepHours}</p>
      </div>
    );
  };

  const renderTraining = () => {
    const { training } = data;
    return (
      <div className="user-profile-section">
        <h2>Training</h2>
        <p>Alcohol Frequency: {training.alcoholFrequency}</p>
        <p>
          Consume Supplements During Training:{" "}
          {training.consumeSupplementsDuringTraining}
        </p>
        <p>Current Symptoms: {training.currentSymptoms.join(", ")}</p>
        <p>Focus Issues: {training.focusIssues}</p>
        <p>Forgetfulness: {training.forgetfulness}</p>
        <p>Improvement Goals: {training.improvementGoals.join(", ")}</p>
        <p>Smoking Habit: {training.smokingHabit}</p>
        <p>Sun Exposure: {training.sunExposure}</p>
        <p>Supplement Type: {training.supplement_type}</p>
        <p>Training Type: {training.trainingType.join(", ")}</p>
        <p>Training Type Other: {training.trainingTypeOther}</p>
        <p>Weekly Exercise Hours: {training.weeklyExerciseHours}</p>
      </div>
    );
  };

  return (
    <div className="user-profile-container">
      <h1>User Profile</h1>
      {renderGeneralQuestions()}
      {renderNutrition()}
      {renderSleepAndIllness()}
      {renderTraining()}
    </div>
  );
};

export default AdminQuestionnaire;
