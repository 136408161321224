import React, { useRef } from "react";
import { useIsHidden } from "../../hooks/useIsHidden";
import classNames from "classnames";
// @ts-ignore
import commentIcon from "../../assets/comment-icon.svg";
// @ts-ignore
import navArrow from "../../assets/nav-arrow.svg";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import { useIsPage } from "../../hooks/useIsPage";

const dropdownOptions = [
  { name: "Download Your Data", value: "Download Your Data" },
  { name: "Download Supplement Data", value: "Download Supplement Data" },
  { name: "Download Something Else", value: "Download Something Else" },
];

interface NavBarActionBtnsProps {
  onDrawerLinkClick?: () => void;
}

const NavBarActionBtns: React.FC<NavBarActionBtnsProps> = ({
  onDrawerLinkClick,
}) => {
  const { isHidden, handleHiddenToggle } = useIsHidden();

  const { isAdmin } = useIsPage();

  const exclusionRef = useRef<HTMLImageElement | null>(null);

  return (
    <>
      <div
        className={classNames("NavItemContainer", "download-button")}
        // onClick={() => handleClick("download")}
      >
        {/* {!isAdmin && (
          <>
            <div
              className={classNames("NavItem-Buttons", {
                // active: active === "download",
              })}
              onClick={handleHiddenToggle}
              ref={exclusionRef}
            >
              Download
              <img src={navArrow} alt="Download icon" className="NavItemIcon" />
            </div>
            {isHidden && (
              <DropdownSelect
                toggleHidden={handleHiddenToggle}
                onDrawerLinkClick={onDrawerLinkClick}
                classNameOverwrite="nav-item-togglable"
                active=""
                options={dropdownOptions}
                exclusionRefs={[exclusionRef]}
              />
            )}
          </>
        )} */}
      </div>
      <div
        className="NavItemContainer"
        // onClick={() => handleClick("support"); onDrawerLinkClick()}
      >
        <div
          className={classNames("NavItem-Buttons", {
            // active: active === "support",
          })}
          onClick={() => (window.location.href = "mailto:info@myoform.io")}
        >
          Support
          <img src={commentIcon} alt="Support icon" className="NavItemIcon" />
        </div>
      </div>
    </>
  );
};

export default NavBarActionBtns;
