import { FC, useState } from "react";
import SmoothScrollLink from "../SmoothScrollLink/SmoothScrollLink";
import classNames from "classnames";
import ActiveDot from "./ActiveDot";
import { NavLink } from "react-router-dom";

// NOTE on how to use:
// the main component is SmoothScrollLink
// for it to work you need to provide unique identifier which you'll attach as id to the element you wanna navigate to
// and to SmoothScrollLink with "to-" prefix as a link

interface Props {
  stepsMapping: Record<string, string>;
  activeDefault: string;
  isEdit?: boolean;
  isNavigate?: boolean;
}

const ProgressLinks: FC<Props> = ({
  activeDefault,
  isEdit = false,
  isNavigate = false,
  stepsMapping,
}) => {
  const [active, setActive] = useState(activeDefault);
  return (
    <div className="navigation-list">
      <div className="navigation-list-line" />
      {Object.entries(stepsMapping).map(([key, value]) => (
        <div
          key={`navigation-list-${key}`}
          className={classNames(
            "progress-links-item",
            active === key && "active"
          )}
          onClick={() => setActive(key)}
        >
          {isNavigate ? (
            <NavLink to={key} className="side-navigation-link">
              <div className="side-navigation-link-with-dot">
                <ActiveDot active={active === key} isEdit={isEdit} />
                <span>{value}</span>
              </div>
            </NavLink>
          ) : (
            <SmoothScrollLink
              key={key}
              to={key}
              className={classNames("side-navigation-link", isEdit && "isEdit")}
            >
              <div className="side-navigation-link-with-dot">
                <ActiveDot active={active === key} isEdit={isEdit} />
                <span>{value}</span>
              </div>
            </SmoothScrollLink>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressLinks;
