import React from "react";
// import { Context } from "../../Context";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import Report from "../Report/Report";
import AdminProductTable from "./AdminProductTable";
import AdminQuestionnaire from "./AdminQuestionnaire";
import classNames from "classnames";
import { UseGetUserById } from "../Auth/FetchUserById";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const questionnaireStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({
  id,
  isQuestionnaire,
  buttonStyle,
  isSnpMapping,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isLoading, error, data: selectedUser } = UseGetUserById(id);
  const tableData = selectedUser?.users?.products;
  const snpMapping = selectedUser?.users?.user?.snp_mapping;
  const tableQuestionnaire = selectedUser?.users?.user?.questionnaire_response;
  return (
    <div>
      <button
        className={classNames(buttonStyle ? buttonStyle : "")}
        onClick={handleOpen}
      >
        See More
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={isQuestionnaire ? questionnaireStyle : style}>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              {isSnpMapping ? (
                <AdminProductTable tableData={snpMapping} isSnpMapping />
              ) : isQuestionnaire ? (
                <AdminQuestionnaire data={tableQuestionnaire} />
              ) : (
                <AdminProductTable tableData={tableData} />
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
