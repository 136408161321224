import React, { useState } from "react";

interface IconProps {
  stroke: string;
}

const SvgIconHoverHoc = (
  WrappedComponent: React.FC<IconProps>,
  overwrite = []
) => {
  const [hover, main] = overwrite;

  return function EnhancedComponent() {
    const [stroke, setStroke] = useState(main || "#C8CAD8");
    return (
      <div
        onMouseEnter={() => setStroke(hover || "#000")}
        onMouseLeave={() => setStroke(main || "#C8CAD8")}
      >
        <WrappedComponent stroke={stroke} />
      </div>
    );
  };
};

export default SvgIconHoverHoc;
