import { useState } from "react";

const useActiveLink = (initialValue = "") => {
  const [active, setActive] = useState(initialValue);

  const handleLinkClick = (id: string) => setActive(id);

  return {
    active,
    handleLinkClick,
  };
};

export default useActiveLink;
