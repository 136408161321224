import { useContext } from "react";
import useResponsive from "../../hooks/useResponsive";
import { useIsPage } from "../../hooks/useIsPage";
import { Context } from "../../helpers/Context";
import classNames from "classnames";
import { SwipeableDrawer } from "@mui/material";
import PersonalSupplement from "../PersonalSupplement/PersonalSupplement";

const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

interface MobileFixedFooterProps {
  onScrollToTop: () => void;
}

const MobileFixedFooter: React.FC<MobileFixedFooterProps> = ({
  onScrollToTop,
}) => {
  const hasAccess = false;

  const { isTinyMobile } = useResponsive();
  const { isDashboard } = useIsPage();
  const { isOpenPersonalSup, setIsOpenPersonalSup } = useContext(Context);

  if (!isTinyMobile || !isDashboard) {
    return null;
  }

  return (
    <div className="mobile-fixed-footer-wrap">
      <button
        onClick={() => setIsOpenPersonalSup(true)}
        className={classNames("button", "mobile-fixed-footer-btn")}
      >
        <span>Personal Supplement</span>
      </button>
      <div
        onClick={onScrollToTop}
        role="button"
        className="mobile-fixed-footer-up"
      />
      <SwipeableDrawer
        anchor={"bottom"}
        open={isOpenPersonalSup}
        onClose={() => setIsOpenPersonalSup(false)}
        onOpen={() => setIsOpenPersonalSup(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <PersonalSupplement
          onClosePersonalSupplement={() => setIsOpenPersonalSup(false)}
          header={null}
        />
      </SwipeableDrawer>
    </div>
  );
};

export default MobileFixedFooter;
