import React, { useEffect, useRef, useState } from "react";
import { useIsHidden } from "../../hooks/useIsHidden";
import ExpandButton from "../ExpandButton/ExpandButton";
import classNames from "classnames";

interface ExpandableSectionProps {
  children: React.ReactElement;
  text?: string;
  isButtonOnLeft?: boolean;
  isScrollable?: boolean;
  arrowChangesColor?: boolean;
  containerCollapsedStyle?: string;
  containerStyle?: string;
  defaultCollapsed: boolean;
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  text = "",
  children,
  isButtonOnLeft = false,
  arrowChangesColor = false,
  containerCollapsedStyle = "",
  containerStyle = "",
  defaultCollapsed = false,
}) => {
  const { isHidden, handleHiddenToggle } = useIsHidden(defaultCollapsed);

  const elementRef = useRef<HTMLDivElement>(null);
  const [dynamicHeight, setDynamicHeight] = useState<string>("initial");

  useEffect(() => {
    if (elementRef.current && !isHidden) {
      const refHeight = elementRef.current.offsetHeight;
      setDynamicHeight(`calc(100% - ${refHeight}px)`);
      return;
    }

    if (isHidden) {
      setDynamicHeight("initial");
      return;
    }
  }, [isHidden]);

  return (
    <div
      className={classNames(
        "expandable-section",
        containerCollapsedStyle && !isHidden ? containerCollapsedStyle : "",
        containerStyle
      )}
    >
      <div
        ref={elementRef}
        onClick={handleHiddenToggle}
        className={classNames(
          "expandable-header-wrap",
          isButtonOnLeft && "reverse"
        )}
      >
        <span>{text}</span>
        <ExpandButton
          arrowChangesColor={arrowChangesColor}
          isHidden={isHidden}
        />
      </div>
      <div style={{ height: dynamicHeight }}>{!isHidden && children}</div>
    </div>
  );
};

export default ExpandableSection;
