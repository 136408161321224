import { SORT } from "./variables";

//@ts-ignore
export const sorting = (a, b, order) => {
  if (order === SORT.ALPHA) {
    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  }
  if (order === SORT.QUANTITY) {
    return b.score - a.score;
  }
  return 0;
};
