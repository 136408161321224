import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./paymentStatus.css"; // Import the CSS file
import { Button } from "@mui/material";
import { Context } from "../../helpers/Context";
import { useCurrentUser } from "../Auth/UseCurrentUser";
import config from "../../config";
import { useMutation } from "react-query";
import axios from "axios";

const PaymentStatusPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleToggleExpandable, report, calculateReport } =
    useContext(Context);
  const { data: currentUser } = useCurrentUser();
  const userId = currentUser?.user?.id;
  const products = report.products || [];
  // Assuming the status is passed as a query parameter like ?status=success or ?status=failure
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  const sendOrderEmail = async () => {
    return axios.post(
      `${config.apiBaseURL}/send_product_order`,
      { user_id: String(userId), products }, // Ensure user_id is a string
      {
        withCredentials: true,
      }
    );
  };

  const { mutate: sendOrder } = useMutation(sendOrderEmail);

  useEffect(() => {
    calculateReport();
  }, [calculateReport]);

  useEffect(() => {
    if (
      status === "success" &&
      userId &&
      report.products &&
      report.products.length > 0
    ) {
      sendOrder();
    }
  }, [userId, status, report]);

  const handleBackToHome = () => {
    navigate("/dashboard");
    handleToggleExpandable();
  };

  return (
    <div className="payment-status-container">
      <div className="status-box">
        {status === "success" ? (
          <h1 className="status-success">Payment Successful!</h1>
        ) : (
          <h1 className="status-failure">Payment Failed!</h1>
        )}
        <p className="status-message">
          {status === "success" ? (
            "Your plan has been ordered successfully, you will receive an email confirmation shortly."
          ) : (
            <span>
              Transaction Unsuccessful. Please verify your payment information
              or contact support at
              <a href="mailto:Info@myoform.io"> info@myoform.io </a>
              if the issue persists.
            </span>
          )}
        </p>
        <Button
          disabled={!userId}
          variant="contained"
          onClick={handleBackToHome}
        >
          Go to Health Report
        </Button>
      </div>
    </div>
  );
};

export default PaymentStatusPage;
