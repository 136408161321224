import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import classNames from "classnames";
import dayjs from "dayjs";

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: 360,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 8,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "8px",
  }),
};

const CustomInput = ({
  input,
  onChange,
  value,
  isEdit,
  isSubmitted,
  error,
}) => {
  const { name, label, type, options, placeholder, multiline } = input;
  const animatedComponents = makeAnimated();

  const handleOptionClick = (option) => {
    onChange(name, option);
  };

  const handleSelectChange = (selectedOptions) => {
    if (Array.isArray(selectedOptions)) {
      const selectedValues = selectedOptions.map((option) => option.value);
      onChange(name, selectedValues);
    } else if (selectedOptions) {
      onChange(name, selectedOptions.value);
    } else {
      onChange(name, "");
    }
  };

  const handleDateChange = (date) => {
    onChange(name, date ? dayjs(date).format("YYYY-MM-DD") : "");
  };
  console.log("data", {
    isSubmitted,
    isEdit,
  });
  return (
    <div className="custom-input-wrap">
      {label && <p className="question-label">{label}</p>}
      {type === "radio" && (
        <div className="question-input-wrap">
          {options.map((option, index) => (
            <>
              <button
                key={index}
                className={classNames(
                  "questionnaire-container-button",
                  {
                    selected: value === option && !isSubmitted && !isEdit,
                    "selected-submitted":
                      value === option && isSubmitted && !isEdit,
                    "edit-selected": value === option && isEdit,
                  },
                  { "input-error": error }
                )}
                onClick={() => handleOptionClick(option)}
                disabled={isSubmitted && !isEdit}
              >
                {option}
              </button>
              {error && <div className="error-message">{error}</div>}
            </>
          ))}
        </div>
      )}

      {(type === "text" || type === "number") && (
        <div className="question-input-wrap">
          <TextField
            type={type}
            placeholder={placeholder}
            hiddenLabel
            variant="outlined"
            multiline={multiline}
            onChange={(e) => onChange(name, e.target.value)}
            value={value}
            error={!!error}
            helperText={error}
            disabled={isSubmitted && !isEdit}
          />
        </div>
      )}
      {type === "checkbox" && (
        <div className="question-input-wrap">
          <input
            type={type}
            name={name}
            checked={value}
            onChange={(e) => onChange(name, e.target.checked)}
            className={classNames({ "input-error": error })}
            disabled={isSubmitted && !isEdit}
          />
          {error && <div className="error-message">{error}</div>}
        </div>
      )}
      {type === "date" && (
        <div className="question-input-wrap">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={value ? dayjs(value, "YYYY-MM-DD") : null}
              onChange={handleDateChange}
              disabled={isSubmitted && !isEdit}
              slots={{
                textField: (props) => (
                  <TextField {...props} error={!!error} helperText={error} />
                ),
              }}
            />
          </LocalizationProvider>
        </div>
      )}
      {type === "select" && (
        <div
          className={classNames(
            "question-input-wrap",
            "question-input-wrap-select-like"
          )}
        >
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            isMulti={true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#9940f68c",
                primary: `${isEdit ? "#9390F9" : "#9940F6"}`,
              },
            })}
            options={options}
            value={
              Array.isArray(value)
                ? value.map((v) => ({ value: v, label: v }))
                : null
            }
            onChange={handleSelectChange}
            styles={customSelectStyles}
            className={classNames({ "input-error": error })}
            isDisabled={isSubmitted && !isEdit}
          />
          {error && <div className="error-message">{error}</div>}
        </div>
      )}
      {type === "textArea" && (
        <div className="question-input-wrap">
          <textarea
            className={classNames("answer-input", { "input-error": error })}
            name={name}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            disabled={isSubmitted && !isEdit}
          />
          {error && <div className="error-message">{error}</div>}
        </div>
      )}
    </div>
  );
};

export default CustomInput;
