import { useNavigate } from "react-router-dom";
import { contentRegister, contentLogin } from "./constants";
import { useEffect, useState } from "react";

export const useAuthPageContent = (toSignUp: boolean) => {
  const content = toSignUp ? contentRegister : contentLogin;

  const navigate = useNavigate();

  const handleActionClick = () => {
    navigate(content.actionLink);
  };

  return {
    handleActionClick,
    content,
  };
};

export const useAlternativeBackground = (alternativesArray: Array<string>) => {
  const [currentBg, setCurrentBg] = useState("");

  useEffect(() => {
    const random = Math.floor(Math.random() * alternativesArray.length);
    const background = alternativesArray[random];
    setCurrentBg(background);
  }, []);

  return currentBg;
};
