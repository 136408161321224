import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import config from "../../config";
import "./planHistory.css";
import { CircularProgress } from "@mui/material";

const fetchSubscriptionHistory = async (id) => {
  const { data } = await axios.get(
    `${config.apiBaseURL}/admin/payment/user/${id}`,
    {
      withCredentials: true,
    }
  );
  return data;
};

const formatDate = (value) => {
  if (!value) return "-";
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const paymentType = {
  "customer.subscription.updated": {
    title: "Failed",
    style: {
      background: "#FFEBEE", // Light red
      color: "#D32F2F", // Dark red
      border: "1px solid #D32F2F", // Dark red
      padding: "0.25rem 1rem",
      borderRadius: "2rem",
      width: "fit-content",
    },
  },
  "invoice.paid": {
    title: "Success",
    style: {
      background: "#E8F5E9", // Light green
      color: "#388E3C", // Dark green
      border: "1px solid #388E3C", // Dark red
      padding: "0.25rem 1rem",
      borderRadius: "2rem",
      width: "fit-content",
    },
  },
  default: {
    title: "-",
    style: {
      background: "#F5F5F5", // Light gray
      color: "#333333", // Dark gray
      border: "1px solid #333333", // Dark red
      padding: "0.25rem 1rem",
      borderRadius: "2rem",
      width: "2rem",
      textAlign: "center",
    },
  },
};

const PlanHistory = ({ userId }) => {
  const { isLoading, data: subscriptionHistory } = useQuery(
    "userSubscriptionHistory",
    () => fetchSubscriptionHistory(userId)
  );
  return (
    <div style={{ height: "75vh", overflow: "auto", margin: "1rem" }}>
      <h2>Plan History</h2>

      {isLoading && <LoadingComponent />}
      {!isLoading && (
        <ul>
          {subscriptionHistory?.map((plan, index) => {
            const planStartDate = formatDate(plan?.subscription_start_date);
            const planEndDate = formatDate(plan?.subscription_end_date);
            const status =
              paymentType[plan?.update_type] || paymentType.default;
            return (
              <li key={index}>
                <div>
                  <span>Title:</span>
                  <span>{plan?.subscription_plan?.title}</span>
                </div>
                <div>
                  <span>Status:</span>
                  <span style={status.style}>{status.title}</span>
                </div>
                <div>
                  <span>Amount:</span>
                  <span>£ {plan?.subscription_plan?.amount}</span>
                </div>
                <div>
                  <span>Duration:</span>
                  <span>{plan?.subscription_plan?.duration} months</span>
                </div>
                {status?.title === "Success" && (
                  <>
                    <div>
                      <span>Start date:</span>
                      <span>{planStartDate}</span>
                    </div>
                    <div>
                      <span>End Date:</span>
                      <span>{planEndDate}</span>
                    </div>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const LoadingComponent = () => {
  return (
    <div
      style={{
        height: "50vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default PlanHistory;
