import { useState } from "react";

export const useIsHidden = (initialFlag = false) => {
  const [isHidden, setIsHidden] = useState(initialFlag);

  const handleHiddenToggle = () => setIsHidden(!isHidden);

  return {
    isHidden,
    handleHiddenToggle,
  };
};
