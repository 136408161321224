import React, { useRef } from "react";
// @ts-ignore
import logoMini from "../../assets/logo-mini.svg";
import classNames from "classnames";
import { useDragScroll } from "../../hooks/useDragAndSroll";
import useResponsive from "../../hooks/useResponsive";
import SmoothScrollLink from "../SmoothScrollLink/SmoothScrollLink";
import { useIsPage } from "../../hooks/useIsPage";
import { useIsHidden } from "../../hooks/useIsHidden";
import NotificationsDropdown from "../Notifications/NotificationsDropdown";

const mockednotifs = [
  {
    message:
      "lorem ipsum blablabla lorem ipsum blablabla lorem ipsum blablabla lorem ipsum blablabla lorem ipsum blablabla lorem ipsum blablabla",
    timestamp: "1h ago",
  },
  {
    message:
      "lorem ipsum blablabla lorem ipsum blablabla  lorem ipsum blablabla lorem ipsum blablabla",
    timestamp: "1h ago",
  },
  {
    message: "lorem ipsum  blablabla lorem ipsum blablabla",
    timestamp: "1h ago",
    isViewed: true,
  },
];

interface NavBarMobileHeaderProps {
  onHiddenToggle: () => void;
  onNotifClick: () => void;
  isDrawerOpened: boolean;
  onGoBack?: () => void;
  isInsideDrawer?: boolean;
  isPersonalSupplement?: boolean;
}

const NavBarMobileHeader: React.FC<NavBarMobileHeaderProps> = ({
  onHiddenToggle,
  onNotifClick,
  isDrawerOpened,
  isInsideDrawer = false,
  isPersonalSupplement = false,
  onGoBack,
}) => {
  // chips logic
  const ref = useDragScroll();
  const { isHidden: isHiddenNotifs, handleHiddenToggle: handleNotifToggle } =
    useIsHidden();
  const exclusionRefNotif = useRef<HTMLDivElement>(null);
  const { isDesktop } = useResponsive();

  const { isDashboard, isHome } = useIsPage();

  const isShowChips = !isDesktop && isDashboard && !isInsideDrawer;

  return (
    <div className={classNames(!isInsideDrawer && "nav-bar-mobile-container")}>
      <div
        className={classNames(
          "horizontalFlex",
          "nav-bar-mobile-wrap",
          isInsideDrawer && "nav-bar-mobile-wrap-mini"
        )}
      >
        {isPersonalSupplement ? (
          <span onClick={onGoBack} role="button" className={"go-back-btn"}>
            &#8592; Go back
          </span>
        ) : (
          <img className="myoform-logo" alt="myoform-logo" src={logoMini} />
        )}
        <div className="horizontalFlex">
          {/* {!isHome && (
            <div style={{ position: "relative" }}>
              <div
                ref={exclusionRefNotif}
                onClick={() => handleNotifToggle()}
                role="button"
                className="icon notif-icon"
              />
              {isHiddenNotifs && (
                <NotificationsDropdown
                  toggleHidden={handleNotifToggle}
                  exclusionRefs={[exclusionRefNotif]}
                  notifs={mockednotifs}
                  styleOverwrite={"notif-dropdown-overwrite"}
                />
              )}
            </div>
          )} */}
          <div
            onClick={onHiddenToggle}
            role="button"
            className={classNames(
              isDrawerOpened && "burger-icon-active",
              "icon",
              "burger-icon"
            )}
          />
        </div>
      </div>
      {isShowChips && (
        <div className="mobile-chips-overlay">
          <div ref={ref} className="mobile-chips">
            {/* TODO should render from map, which right now doesnt exists */}
            {/* active can be calculate with property 'to' by clicking on span */}
            <SmoothScrollLink
              to={"nutritional-report"}
              className={"mobile-chip-wrap"}
            >
              <span>Nutritional Report</span>
            </SmoothScrollLink>
            <SmoothScrollLink
              to={"training-report"}
              className={"mobile-chip-wrap"}
            >
              <span>Training Report</span>
            </SmoothScrollLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBarMobileHeader;
