import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import axios, { AxiosError } from "axios";
import config from "../../config";
import { useMutation, useQueryClient } from "react-query";
import { string } from "yup";

interface ErrorResponse {
  message: string;
  detail?: string;
}

const passwordValidation = string()
  .required("Please enter your password")
  .min(7, "Password must be at least 7 characters long");

const setUserPassword = async (password: string) => {
  const { data } = await axios.post(
    `${config.apiBaseURL}/auth/enforce-password`,
    { password },
    {
      withCredentials: true,
    }
  );
  return data;
};

const EnforcePasswordDialog = ({
  enforcePassword = false,
  setEnforcePassword = (value: boolean) => {},
}) => {
  const queryClient = useQueryClient();
  const [value, setValue] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const {
    mutate: enforcePasswordMutation,
    error,
    isError,
    reset,
  } = useMutation({
    mutationFn: setUserPassword,
    onSuccess: (data) => {
      setEnforcePassword(false);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      setEnforcePassword(false);
    },
  });

  const handleSubmit = (value: string) => {
    passwordValidation
      .validate(value)
      .then(() => enforcePasswordMutation(value))
      .catch((error) => {
        setPasswordError(error?.message);
      });
  };

  return (
    <>
      <Dialog open={enforcePassword} onClose={() => setEnforcePassword(false)}>
        <DialogTitle>Set Your Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            As this is your first login, please create a new password to secure
            your account.
          </DialogContentText>
          <TextField
            value={value}
            autoFocus
            margin="dense"
            id="password"
            label="Enter password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setValue(e?.target?.value);
              if (passwordError) {
                setPasswordError(""); // Clear the error message when user starts typing
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                const password = (e.target as HTMLInputElement).value;
                handleSubmit(password);
              }
            }}
          />
          {passwordError && <div className="errorClass">{passwordError}</div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEnforcePassword(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit(value);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Toast error if enforce password failed */}
      <Snackbar
        sx={{ marginLeft: "200px" }}
        open={isError}
        autoHideDuration={6000}
        onClose={() => reset()}
      >
        <Alert onClose={() => reset()} severity="error">
          {error?.response?.data?.detail ||
            error?.response?.data?.message ||
            "An error occurred"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EnforcePasswordDialog;
