import React from "react";
import subscription from "../../assets/subscription-pack.jpeg";
import "./subscription.css";
import classNames from "classnames";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import config from "../../config";
import { CircularProgress } from "@mui/material";
import { CheckCircleOutlineSharp } from "@mui/icons-material";

const fetchSubscriptionPlans = async () => {
  const { data } = await axios.get(`${config.apiBaseURL}/subscription`, {
    withCredentials: true,
  });
  return data;
};

const createCheckoutSession = async (subscriptionPlanId) => {
  const { data } = await axios.post(
    `${config.apiBaseURL}/create-checkout-session`,
    { subscription_id: subscriptionPlanId },
    {
      withCredentials: true,
    }
  );
  return data;
};

const SubscriptionPayment = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["subscriptionPlans"],
    queryFn: fetchSubscriptionPlans,
  });

  const { mutate, isLoading: createCheckoutSessionLoading } = useMutation({
    mutationFn: createCheckoutSession,
    onSuccess: (data) => {
      window.location.href = data?.checkout_url;
    },
  });

  return (
    <div className="main-container">
      {/* <nav className="navbar">
        <img src={logo} alt="logo" />
        <span>SIGN UP TO GET EARLY BIRD ACCESS</span>
        <Link to={"/sign-up"} className="signup-btn">
          SIGN UP
        </Link>
      </nav> */}

      <section id="subscriptionList-section">
        <div>
          <h1>Chose your pricing plan</h1>
          <h2>Find one that works for you</h2>
        </div>
        {isLoading ? (
          <LoadingComponent />
        ) : data?.length > 0 ? (
          <>
            <div className="cards-container">
              {data?.map((plan, index) => {
                return (
                  <div key={plan?.id || index} className="subscription-card">
                    <img src={subscription} alt="subscription plan image" />

                    <div className="subscription-card-content font-avenir">
                      <h2 className="font-raleway">{plan?.duration} Months</h2>

                      <div className="subscription-price">
                        <div className="font-raleway">
                          <span>£</span>
                          <span>{plan?.amount || 0}</span>
                        </div>
                        <p>Every month</p>
                      </div>

                      <p>{`A ${plan?.duration} months supply of your personalised supplement - paid on a monthly subscription fee.`}</p>
                      <span>Validate for {plan?.duration} months</span>

                      <button
                        disabled={createCheckoutSessionLoading}
                        className={classNames("subscribe-button font-avenir")}
                        onClick={() => mutate(plan?.id)}
                      >
                        Select
                      </button>

                      <hr />
                      <div className="subscription-para">
                        <CheckCircleOutlineSharp />
                        <h3>{`A ${plan?.duration} months supply of your genetically personalized supplement`}</h3>
                      </div>

                      <hr />

                      <div className="subscription-para">
                        <CheckCircleOutlineSharp />
                        <h3>
                          {
                            "Access to the Myoform DNA Health reporting platform"
                          }
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="font-avenir">Cancellation fees may apply.</div>
          </>
        ) : (
          <NoPlansComponent />
        )}
      </section>

      {/* Footer */}
      {/* <footer>
        <div>
          <a href="https://www.facebook.com/WileyNutrition">
            <img src={facebook} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/wileynutrition/?hl=en-gb">
            <img src={insta} alt="insta" />
          </a>
          <a href="https://www.linkedin.com/company/53473846/">
            <img src={linkedin} alt="linkedin" />
          </a>
        </div>

        <a
          data-auto-recognition="true"
          href="mailto:Info@myoform.io"
          className=""
        >
          Info@myoform.io
        </a>

        <div>©2020 by Wiley Nutrition Limited, London, UK</div>
      </footer> */}
    </div>
  );
};

const LoadingComponent = () => {
  return (
    <div
      style={{
        height: "50vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const NoPlansComponent = () => {
  return (
    <h2
      style={{
        height: "50vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      No plans
    </h2>
  );
};

export default SubscriptionPayment;
