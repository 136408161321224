import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import {
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import AuthFooter from "./AuthFooter";
import { Formik, Field, Form } from "formik";
import { object, string } from "yup";
import axios, { AxiosError } from "axios";
import config from "../../config";
import AuthHeader from "./AuthHeader";
import { useAlternativeBackground } from "./hooks";
import { alternativeBackgrounds } from "./constants";
import classNames from "classnames";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import { GoogleLogin } from "@react-oauth/google";

interface ErrorResponse {
  message: string;
}

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const loginMutation = useMutation(
    async (credentials: { email: string; password: string }) => {
      const { data } = await axios.post(
        `${config.apiBaseURL}/auth/login`,
        credentials,
        {
          withCredentials: true,
        }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("currentUser", {
          authenticated: true,
          user: data,
        });
        navigate("/");
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        setErrorSnackbarOpen(true);
        const message = error.response?.data.message || "An error occurred";
        setErrorMessage(message);
      },
    }
  );

  const googleLoginMutation = useMutation(
    async (access_token: string) => {
      const { data } = await axios.post(
        `${config.apiBaseURL}/auth/google`,
        { access_token },
        {
          withCredentials: true,
        }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("currentUser", {
          authenticated: true,
          user: data,
        });
        navigate("/");
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        setErrorSnackbarOpen(true);
        const message = error.response?.data.message || "An error occurred";
        setErrorMessage(message);
      },
    }
  );

  const resetPasswordMutation = useMutation(
    async (email: string) => {
      const { data } = await axios.post(
        `${config.apiBaseURL}/reset-password`,
        { email },
        {
          withCredentials: true,
        }
      );
      return data;
    },
    {
      onSuccess: () => {
        setForgotPasswordOpen(false);
        alert("Password reset email sent. Please check your inbox.");
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        setErrorSnackbarOpen(true);
        const message = error.response?.data.message || "An error occurred";
        setErrorMessage(message);
      },
    }
  );

  const alternativeBackground = useAlternativeBackground(
    alternativeBackgrounds
  );

  const handleForgotPassword = (email: string) => {
    resetPasswordMutation.mutate(email);
  };

  return (
    <div className={classNames("sign-in-wrap", alternativeBackground)}>
      <AuthHeader toSignUp={false} />
      <div className="sign-in-content">
        <div className="sign-in-main">
          <div className="sign-in-logo" />
          <h2>Welcome Back</h2>
          <span>Log in to your account</span>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={object({
              email: string()
                .required("Please enter your email")
                .email("Invalid email"),
              password: string()
                .required("Please enter your password")
                .min(7, "Password must be at least 7 characters long"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              loginMutation
                .mutateAsync(values)
                .finally(() => setSubmitting(false));
            }}
          >
            {({ errors, touched, values }) => (
              <Form className="sign-in-form">
                <Field
                  as={TextField}
                  name="email"
                  type="email"
                  placeholder="Email"
                  hiddenLabel
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.email && touched.email)}
                  helperText={touched.email && errors.email}
                />
                <Field
                  as={TextField}
                  name="password"
                  type="password"
                  placeholder="Password"
                  hiddenLabel
                  fullWidth
                  error={Boolean(errors.password && touched.password)}
                  helperText={touched.password && errors.password}
                />
                <ButtonWithIcon
                  type="submit"
                  classNameOverWrite={classNames("auth-desktop-only")}
                  text="Log In"
                  onClick={() => {
                    console.log("log-in action");
                  }}
                />
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    googleLoginMutation.mutate(
                      credentialResponse.credential as string
                    );
                  }}
                  onError={() => {
                    setErrorMessage("Login With Google Failed");
                    setErrorSnackbarOpen(true);
                  }}
                />
                <AuthFooter
                  onButtonClick={() => loginMutation.mutate(values)} // Pass the form values to the mutation
                  toSignUp={false}
                />
              </Form>
            )}
          </Formik>
          <span
            className="sign-in-password-restore"
            onClick={() => setForgotPasswordOpen(true)}
          >
            Forgot password?
          </span>
        </div>
        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          sx={{ marginLeft: "200px" }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
      <Dialog
        open={forgotPasswordOpen}
        onClose={() => setForgotPasswordOpen(false)}
      >
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address to receive a password reset link.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                const email = (e.target as HTMLInputElement).value;
                handleForgotPassword(email);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setForgotPasswordOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              const email = (
                document.getElementById("email") as HTMLInputElement
              ).value;
              handleForgotPassword(email);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SignIn;
