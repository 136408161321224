import { FC } from "react";

interface Props {
  text: string;
  onClick: () => void;
  isCheckedStatus: boolean;
  disabled?: boolean;
}

const CustomCheckbox: FC<Props> = ({
  text,
  onClick,
  isCheckedStatus,
  disabled = false,
}) => {
  return (
    <div className={`custom-checkbox-wrap ${disabled ? "disabled" : ""}`}>
      <label className="custom-checkbox">
        <input
          type="checkbox"
          id="checkbox"
          checked={isCheckedStatus}
          onClick={() => {
            if (!disabled) {
              onClick();
            }
          }}
          disabled={disabled}
        />
        <span className="custom-checkbox-checkmark"></span>
      </label>
      <span
        className={`custom-checkbox-text ${isCheckedStatus ? "checked" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {text}
      </span>
    </div>
  );
};

export default CustomCheckbox;
