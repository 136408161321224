import React, { useState, useMemo, useEffect } from "react";
import config from "../../config";
import ChunkedUploady, {
  useChunkFinishListener,
  useBatchFinishListener,
} from "@rpldy/chunked-uploady";
import { UseGetUserById } from "../Auth/FetchUserById";
import UploadButton from "@rpldy/upload-button";
import Button from "react-bootstrap/Button";
import uuid4 from "uuid4";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import "./admin.css";
import classNames from "classnames";

function AdminGeneticUpload(props) {
  const { userId, setErrorMessage, setErrorSnackbarOpen } = props;
  const requestID = useMemo(() => uuid4(), []);
  const [progress, setProgress] = useState(0);
  const { isLoading, refetch, data: selectedUser } = UseGetUserById(userId);
  const [allChunksSentUsers, setAllChunksSentUsers] = useState([]);
  const userData = selectedUser?.users?.user;
  const hasReport = userData?.has_report;
  const shouldShowUploadButton = !hasReport;
  const [sentEmailUsers, setSentEmailUsers] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Contact User");

  const TheApp = ({ children }) => {
    useChunkFinishListener(async (chunk) => {
      setProgress(chunk.item.completed);
      if (chunk.item.completed === 100) {
        await axios.put(
          `${config.apiBaseURL}/admin_all_chunks_accepted/${userId}`,
          null,
          {
            withCredentials: true,
          }
        );
        if (chunk.item.completed === 100) {
          setAllChunksSentUsers((prevUsers) => [...prevUsers, userId]);
        }
      }
    });

    useBatchFinishListener((batch) => {
      batch.items.forEach((item) => {
        if (item.uploadResponse) {
          if (item.state === "error") {
            setErrorSnackbarOpen(true);
            setErrorMessage("An error has occured.");
          }
        }
      });
    });

    return children;
  };

  const handleSendEmail = async (email) => {
    try {
      setIsButtonDisabled(true);
      setButtonText("Sending...");
      const response = await axios.post(
        `${config.apiBaseURL}/send_report_email`,
        { email },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setSentEmailUsers((prevUsers) =>
          prevUsers.filter((user) => user !== email)
        );
        setTimeout(() => {
          setIsButtonDisabled(false);
          setButtonText("SENT!");
        }, 5000);
      } else {
        setErrorMessage("An error has occurred.");
        setIsButtonDisabled(false);
        setButtonText("Contact User");
      }
    } catch (error) {
      setErrorMessage(error);
      console.log("error: ", error);
      setIsButtonDisabled(false);
      setButtonText("Contact User");
    }
  };

  const getProgressBar = () => {
    const progressColor = progress === 100 ? "greenComplete" : "blueProgress";
    return (
      <div>
        {progress && !hasReport ? (
          <>
            <div className="styled-circle-progress-number">
              {`${progress.toFixed(0)} %`}
            </div>
            <LinearProgress
              variant="determinate"
              value={progress}
              color={progressColor}
              style={{ width: "100%" }}
            />
          </>
        ) : (
          " "
        )}
      </div>
    );
  };

  useEffect(() => {
    const pollInterval = setInterval(() => {
      if (allChunksSentUsers.includes(userId) && !hasReport) {
        refetch();
      } else if (hasReport) {
        setAllChunksSentUsers((prevUsers) =>
          prevUsers.filter((user) => user !== userId)
        );
      }
    }, 3000);

    return () => {
      clearInterval(pollInterval);
    };
  }, [refetch, hasReport, userId, allChunksSentUsers]);

  return (
    <div>
      {shouldShowUploadButton ? (
        <ChunkedUploady
          chunkSize={5000000}
          params={{ request_id: requestID }}
          chunked
          parallel={5}
        >
          <TheApp>
            <div className="admin-form-button-upload">
              <UploadButton
                className={classNames(
                  "genetic-file-button",
                  "adminGeneticUploadButton"
                )}
                destination={{
                  url: `${config.apiBaseURL}/admin_genomic_data/${userId}`,
                }}
                withCredentials
              >
                Upload
              </UploadButton>
            </div>
          </TheApp>
        </ChunkedUploady>
      ) : (
        <button
          className={classNames("genetic-file-button", "admin-table-button")}
          onClick={() => handleSendEmail(userData?.email)}
          disabled={isButtonDisabled}
        >
          {buttonText}
        </button>
      )}
      {getProgressBar()}
    </div>
  );
}
export default AdminGeneticUpload;
