import { useQuery } from "react-query";
import axios from "axios";
import config from "../../config";

export const UseGetUserById = (userId) => {
  return useQuery(
    ["selectedUser", userId],
    async () => {
      const { data } = await axios.get(`${config.apiBaseURL}/users/${userId}`, {
        withCredentials: true,
      });
      const adminData = {
        users: data,
      };
      return adminData;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
};
