import { useState, useEffect } from "react";

// Custom hook for responsive breakpoints
const useResponsive = () => {
  const queryTinyMobile = "(max-width: 500px)";
  const queryMobile = "(max-width: 767px)";
  const queryTablet = "(min-width: 768px) and (max-width: 1239px)";
  const querySmallDesktop = "(min-width: 1240px) and (max-width: 1439px)";
  const queryBigDesktop = "(min-width: 1440px)";

  // State for each device type
  const [isTinyMobile, setIsTinyMobile] = useState<boolean>(
    window.matchMedia(queryTinyMobile).matches
  );
  const [isMobile, setIsMobile] = useState<boolean>(
    window.matchMedia(queryMobile).matches
  );
  const [isTablet, setIsTablet] = useState<boolean>(
    window.matchMedia(queryTablet).matches
  );
  const [isBigDesktop, setIsBigDesktop] = useState<boolean>(
    window.matchMedia(queryBigDesktop).matches
  );
  const [isSmallDesktop, setIsSmallDesktop] = useState<boolean>(
    window.matchMedia(querySmallDesktop).matches
  );

  useEffect(() => {
    // Event handler for media query change
    const handleMediaChange = (event: MediaQueryListEvent): void => {
      switch (event.media) {
        case queryTinyMobile:
          setIsTinyMobile(event.matches);
          break;
        case queryMobile:
          setIsMobile(event.matches);
          break;
        case queryTablet:
          setIsTablet(event.matches);
          break;
        case queryBigDesktop:
          setIsBigDesktop(event.matches);
          break;
        case querySmallDesktop:
          setIsSmallDesktop(event.matches);
          break;
        default:
          break;
      }
    };

    // Create MediaQueryList objects
    const mobileTinyMediaQuery = window.matchMedia(queryTinyMobile);
    const mobileMediaQuery = window.matchMedia(queryMobile);
    const tabletMediaQuery = window.matchMedia(queryTablet);
    const desktopMediaQuery = window.matchMedia(queryBigDesktop);
    const desktopSmallMediaQuery = window.matchMedia(querySmallDesktop);

    // Add event listeners
    mobileTinyMediaQuery.addEventListener("change", handleMediaChange);
    mobileMediaQuery.addEventListener("change", handleMediaChange);
    tabletMediaQuery.addEventListener("change", handleMediaChange);
    desktopMediaQuery.addEventListener("change", handleMediaChange);
    desktopSmallMediaQuery.addEventListener("change", handleMediaChange);

    // Cleanup function to remove event listeners
    return () => {
      mobileTinyMediaQuery.removeEventListener("change", handleMediaChange);
      mobileMediaQuery.removeEventListener("change", handleMediaChange);
      tabletMediaQuery.removeEventListener("change", handleMediaChange);
      desktopMediaQuery.removeEventListener("change", handleMediaChange);
      desktopSmallMediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  return {
    isTinyMobile,
    isMobile: isMobile || isTinyMobile,
    isTablet,
    isBigDesktop,
    isSmallDesktop,
    isDesktop: isSmallDesktop || isBigDesktop,
  };
};

export default useResponsive;
