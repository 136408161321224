const Note = ({ color }: { color?: string }) => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.6004 8.5H19.4004C18.8004 8.5 18.4004 8.9 18.4004 9.5V80.5C18.4004 81.1 18.8004 81.5 19.4004 81.5H70.6004C71.2004 81.5 71.6004 81.1 71.6004 80.5V9.5C71.6004 9 71.2004 8.5 70.6004 8.5ZM69.6004 79.5H20.4004V10.5H69.6004V79.5Z"
      fill="url(#paint0_linear_725_7062)"
    />
    <path
      d="M27.002 24.8H31.802V29.6C31.802 30.2 32.202 30.6 32.802 30.6C33.402 30.6 33.802 30.2 33.802 29.6V24.8H38.602C39.202 24.8 39.602 24.4 39.602 23.8C39.602 23.2 39.202 22.8 38.602 22.8H33.802V18C33.802 17.4 33.402 17 32.802 17C32.202 17 31.802 17.4 31.802 18V22.8H27.002C26.402 22.8 26.002 23.2 26.002 23.8C26.002 24.4 26.402 24.8 27.002 24.8Z"
      fill="url(#paint1_linear_725_7062)"
    />
    <path
      d="M27.002 38.5H62.802C63.402 38.5 63.802 38.1 63.802 37.5C63.802 36.9 63.402 36.5 62.802 36.5H27.002C26.402 36.5 26.002 36.9 26.002 37.5C26.002 38.1 26.402 38.5 27.002 38.5Z"
      fill="url(#paint2_linear_725_7062)"
    />
    <path
      d="M27.002 45H62.802C63.402 45 63.802 44.6 63.802 44C63.802 43.4 63.402 43 62.802 43H27.002C26.402 43 26.002 43.4 26.002 44C26.002 44.6 26.402 45 27.002 45Z"
      fill="url(#paint3_linear_725_7062)"
    />
    <path
      d="M27.002 51.5H62.802C63.402 51.5 63.802 51.1 63.802 50.5C63.802 49.9 63.402 49.5 62.802 49.5H27.002C26.402 49.5 26.002 49.9 26.002 50.5C26.002 51.1 26.402 51.5 27.002 51.5Z"
      fill="url(#paint4_linear_725_7062)"
    />
    <path
      d="M27.002 58H62.802C63.402 58 63.802 57.6 63.802 57C63.802 56.4 63.402 56 62.802 56H27.002C26.402 56 26.002 56.4 26.002 57C26.002 57.6 26.402 58 27.002 58Z"
      fill="url(#paint5_linear_725_7062)"
    />
    <path
      d="M27.002 64.5H62.802C63.402 64.5 63.802 64.1 63.802 63.5C63.802 62.9 63.402 62.5 62.802 62.5H27.002C26.402 62.5 26.002 62.9 26.002 63.5C26.002 64.1 26.402 64.5 27.002 64.5Z"
      fill="url(#paint6_linear_725_7062)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_725_7062"
        x1="20.3431"
        y1="34.7051"
        x2="71.6615"
        y2="34.9918"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_725_7062"
        x1="26.4986"
        y1="21.8821"
        x2="39.6172"
        y2="21.9826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_725_7062"
        x1="27.3823"
        y1="37.2179"
        x2="63.0969"
        y2="42.3921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_725_7062"
        x1="27.3823"
        y1="43.7179"
        x2="63.0969"
        y2="48.8921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_725_7062"
        x1="27.3823"
        y1="50.2179"
        x2="63.0969"
        y2="55.3921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
      <linearGradient
        id="paint5_linear_725_7062"
        x1="27.3823"
        y1="56.7179"
        x2="63.0969"
        y2="61.8921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
      <linearGradient
        id="paint6_linear_725_7062"
        x1="27.3823"
        y1="63.2179"
        x2="63.0969"
        y2="68.3921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color={color ? color : "#9940F6"} />
        <stop offset="1" stop-color={color ? color : "#6D57FC"} />
      </linearGradient>
    </defs>
  </svg>
);

export default Note;
