import classNames from "classnames";
import React from "react";
import useCloseTogglable from "../../hooks/useCloseTogglable";

type OptionItem = {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  name: string;
  value: string;
};

interface DropdownSelectProps {
  classNameOverwrite: string;
  active: OptionItem["name"];
  options: Array<OptionItem>;
  toggleHidden: () => void;
  exclusionRefs: React.RefObject<HTMLElement>[];
  onDrawerLinkClick?: () => void;
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  classNameOverwrite,
  active,
  options,
  toggleHidden,
  exclusionRefs,
  onDrawerLinkClick,
}) => {
  const ref = useCloseTogglable(exclusionRefs, toggleHidden);

  return (
    <div
      ref={ref}
      className={classNames("togglable", "sort-togglable", classNameOverwrite)}
    >
      {options.map((item) => (
        <span
          key={item.name}
          onClick={(e) => {
            if (item.onClick) {
              item.onClick(e);
            }
            if (onDrawerLinkClick) {
              onDrawerLinkClick();
            }
          }}
          className={classNames(
            active && active === item.value ? "active" : ""
          )}
        >
          {item.name}
        </span>
      ))}
    </div>
  );
};

export default DropdownSelect;
