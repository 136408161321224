import React from "react";
import ContentList from "./ContentList";
import ProgressLinks from "./ProgressLinks";
import { useIsPage } from "../../hooks/useIsPage";
import AdminNav from "./AdminNav";
// @ts-ignore
import logo from "../../assets/myoform-logo-web.svg";
import { useNavigate } from "react-router-dom";
import { stepsMapping, howToReadContent } from "../../helpers/variables";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";

const pages = {
  home: "Home",
  questionnaire: "Questionnaire",
  how_to_read: "How To Read",
  dashboard: "Health Report",
} as const;

type PageKeys = keyof typeof pages;

const howToReadSteps = Object.assign(
  { welcome: "Welcome!" },
  howToReadContent.content.reduce<Record<string, string>>((res, item) => {
    const { identifier, heading } = item;

    res[identifier] = heading;
    return res;
  }, {})
);

const SideNavigation: React.FC = () => {
  const {
    isHowToRead,
    isQuestionnaire,
    isAdmin,
    isSettings,
    isDashboard,
    isHome,
    isCustomLocation,
  } = useIsPage("/user-subscription");
  const navigate = useNavigate();
  const { data: currentUser } = useCurrentUser();
  const isQuestionnaireAnswered = currentUser?.user?.has_report || false;
  const hasConfirmedHowToRead =
    currentUser?.user?.has_confirmed_how_to_read || false;

  // Conditionally filter pages
  const filteredPages = Object.keys(pages).reduce((acc, key) => {
    const pageKey = key as PageKeys;
    if (pageKey === "how_to_read" || pageKey === "dashboard") {
      if (isQuestionnaireAnswered && hasConfirmedHowToRead) {
        acc[pageKey] = pages[pageKey];
      }
    } else {
      acc[pageKey] = pages[pageKey];
    }
    return acc;
  }, {} as Record<PageKeys, string>);

  return (
    <div className="side-navigation-wrap">
      <img
        className="side-navigation-logo"
        src={logo}
        alt="myoform-logo"
        onClick={() => navigate("/")}
      />
      <div className="side-navigation-main">
        {isHome && (
          <ProgressLinks
            activeDefault="home"
            stepsMapping={filteredPages} // Use the filtered pages here
            isNavigate={true}
          />
        )}
        {isDashboard && <ContentList />}
        {isQuestionnaire && (
          <ProgressLinks
            activeDefault="generalQuestions"
            isEdit={false}
            stepsMapping={stepsMapping}
          />
        )}
        {isHowToRead && (
          <ProgressLinks
            activeDefault="welcome"
            isEdit={false}
            stepsMapping={howToReadSteps}
          />
        )}
        {(isAdmin || isSettings || isCustomLocation) && <AdminNav />}
      </div>
    </div>
  );
};

export default SideNavigation;
