const MagnifyingGlass = ({ color }: { color?: string }) => (
  <svg
    width="100"
    height="70"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="8"
      stroke={color ? color : "#ffffff"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 21L16.7 16.7"
      stroke={color ? color : "#ffffff"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MagnifyingGlass;
