import React, { useEffect } from "react";
import useResponsive from "../../hooks/useResponsive";
import { useIsPage } from "../../hooks/useIsPage";

interface SmoothScrollLink {
  to: string;
  className: string;
  children: React.ReactElement;
  onClick?: (prop?: any) => void;
}

const SmoothScrollLink: React.FC<SmoothScrollLink> = ({
  to,
  children,
  className,
  onClick,
}) => {
  const { isMobile } = useResponsive();
  const { isDashboard } = useIsPage();
  // Function to handle smooth scrolling
  // @ts-ignore
  const handleScroll = (e) => {
    e.preventDefault();
    const targetElement = document.getElementById(to);

    if (targetElement) {
      if (isMobile && isDashboard) {
        const yOffset = -130;
        const yPosition =
          targetElement.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({ top: yPosition, behavior: "smooth" });
      } else {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    // Add event listener
    const linkElement = document.getElementById(`${to}-link`);
    if (linkElement) {
      linkElement.addEventListener("click", handleScroll);

      // Cleanup
      return () => linkElement.removeEventListener("click", handleScroll);
    }
  }, [to]);

  return (
    <a
      id={`${to}-link`}
      className={className}
      href={`#${to}`}
      onClick={(e) => {
        if (onClick) {
          onClick();
        }
        handleScroll(e);
      }}
    >
      {children}
    </a>
  );
};

export default SmoothScrollLink;
