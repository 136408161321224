import classNames from "classnames";
import { useContext } from "react";
import { Context } from "../../helpers/Context";
import { useIsPage } from "../../hooks/useIsPage";

interface ExpandableInfoProps {
  children: React.ReactElement;
}

const ExpandableInfo: React.FC<ExpandableInfoProps> = ({ children = null }) => {
  const { isExpandedInfoHidden, handleToggleExpandable } = useContext(Context);
  const {
    isSettings,
    isAdmin,
    isHome,
    isCustomLocation,
    isQuestionnaire,
    isHowToRead,
  } = useIsPage("/user-subscription");

  if (
    isAdmin ||
    isSettings ||
    isHome ||
    isCustomLocation ||
    isQuestionnaire ||
    isHowToRead
  ) {
    return null;
  }

  return (
    <section
      className={classNames(
        "expandableInfoWrap",
        isExpandedInfoHidden && "expandableInfoWrap_hidden"
      )}
    >
      {!isExpandedInfoHidden && (
        <div className="expandableInfoHidableWrap">{children}</div>
      )}
      <button
        onClick={() => handleToggleExpandable()}
        className="expandableInfoCollapseBtn"
      />
    </section>
  );
};

export default ExpandableInfo;
