const HealthHeart = ({ color }: { color?: string }) => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 52.8154H21.5664V68.3818C21.5664 68.9346 22.0137 69.3818 22.5664 69.3818H37.307C39.7039 72.569 42.8405 75.6011 46.6631 78.3906C52.5029 82.6523 57.7988 84.835 58.0215 84.9258C58.1426 84.9756 58.2715 85 58.3994 85C58.5273 85 58.6562 84.9756 58.7773 84.9258C59 84.835 64.2969 82.6523 70.1367 78.3906C77.9346 72.7002 82.8975 66.0049 84.4883 59.0283C86.7354 49.1768 81.3213 42.0723 75.3389 39.6406C73.3537 38.8333 71.3405 38.5039 69.3818 38.6119V22.5664C69.3818 22.0137 68.9346 21.5664 68.3818 21.5664H52.8154V6C52.8154 5.44727 52.3682 5 51.8154 5H22.5664C22.0137 5 21.5664 5.44727 21.5664 6V21.5664H6C5.44727 21.5664 5 22.0137 5 22.5664V51.8154C5 52.3682 5.44727 52.8154 6 52.8154ZM74.5859 41.4932C79.8096 43.6172 84.5264 49.8711 82.5391 58.583C79.2119 73.1709 61.2461 81.6514 58.3994 82.9111C55.5537 81.6514 37.5879 73.1709 34.2598 58.583C32.2734 49.8701 36.9912 43.6162 42.2148 41.4932C43.5918 40.9326 45.1406 40.5957 46.751 40.5957C50.46 40.5957 54.501 42.3857 57.5459 47.374C57.7275 47.6719 58.0508 47.8535 58.3994 47.8535C58.748 47.8535 59.0713 47.6719 59.2529 47.375C63.6211 40.2178 70.0381 39.6445 74.5859 41.4932ZM7 23.5664H22.5664C23.1191 23.5664 23.5664 23.1191 23.5664 22.5664V7H50.8154V22.5664C50.8154 23.1191 51.2627 23.5664 51.8154 23.5664H67.3818V38.8809C63.9574 39.6133 60.8016 41.725 58.3994 45.0479C54.1973 39.2324 47.6943 37.1094 41.4619 39.6396C35.4795 42.0723 30.0635 49.1768 32.3105 59.0283C32.9604 61.8767 34.1815 64.6765 35.9187 67.3818H23.5664V51.8154C23.5664 51.2627 23.1191 50.8154 22.5664 50.8154H7V23.5664Z"
      fill={color ? color : "#9940f6"}
    />
    <path
      d="M73.642 50.9624C75.0669 51.1052 76.394 51.8962 77.2828 53.1322C78.2946 54.5384 78.611 56.3643 78.176 58.2738C78.0375 58.8802 78.4166 59.4834 79.0219 59.6207C79.1065 59.6405 79.19 59.6492 79.2735 59.6492C79.7866 59.6492 80.2502 59.2955 80.3688 58.7747C80.95 56.227 80.5029 53.7573 79.1098 51.8193C77.8453 50.0593 75.9337 48.931 73.8661 48.7233C73.2454 48.6684 72.6961 49.1123 72.6345 49.7308C72.573 50.3493 73.0234 50.8997 73.642 50.9624Z"
      fill={color ? color : "#9940f6"}
    />
  </svg>
);

export default HealthHeart;
