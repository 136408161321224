const LockedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clipPath="url(#clip0_682_9932)">
      <path
        d="M6 7.5V5.25C6 3.59315 7.34315 2.25 9 2.25C10.6569 2.25 12 3.59315 12 5.25V7.5"
        stroke="#8E95A9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.75 7.5H14.25V14.25C14.25 15.0784 13.5784 15.75 12.75 15.75H5.25C4.42157 15.75 3.75 15.0784 3.75 14.25V7.5Z"
        stroke="#8E95A9"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x="10.875"
        y="11.625"
        width="0.0075"
        height="0.0075"
        stroke="#8E95A9"
        strokeWidth="2.25"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_682_9932">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LockedIcon;
