import React, { useState, useEffect, useContext } from "react";
import AdminGeneticUpload from "../admin/AdminGeneticUpload";
import RegisterModal from "./RegisterModal";
import Container from "react-bootstrap/Container";
import Modal from "./AdminModal";
import { useNavigate } from "react-router-dom";
import { useGetUsers } from "../Auth/FetchAllUsers";
import axios from "axios";
import config from "../../config";
import { useMutation, useQueryClient } from "react-query";
import { Snackbar, Alert } from "@mui/material";
import { Context } from "../../helpers/Context";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import TrashIcon from "../../assets/svgComponents/TrashIcon";
import EditIcon from "../../assets/svgComponents/EditIcon";
import getSvgHoverIcon from "../../assets/svgComponents/SvgIconHoverHoc";
import LeftArrow from "../../assets/svgComponents/LeftArrow";
import RightArrow from "../../assets/svgComponents/RightArrow";
import classNames from "classnames";

const getArrowStroke = (flag) =>
  flag ? "rgba(142, 149, 169, .4)" : "rgba(142, 149, 169, 1)";

const AdminTable = () => {
  const queryClient = useQueryClient();
  const { isLoading, error, refetch, data: adminData } = useGetUsers();
  const { setUserId } = useContext(Context);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [searchName, setSearchName] = useState("");
  const navigate = useNavigate();
  const totalUsers =
    adminData?.users?.filter((user) => !user.deleted)?.length || 0;

  const totalPages = Math.ceil(totalUsers / usersPerPage);

  const handleSeeMore = (id) => {
    setUserId(id);
    navigate("/dashboard");
  };

  const [usersData, setUsersData] = useState(null);
  useEffect(() => {
    if (adminData) {
      setUsersData({
        ...adminData,
        users: adminData.users
          .filter((user) => !user.deleted)
          .sort((a, b) => b.id - a.id),
      });
    }
  }, [adminData]);

  const getCurrentPageUsers = () => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const filteredUsers = usersData?.users?.filter((user) =>
      `${user?.first_name} ${user?.last_name}`
        .toLowerCase()
        .includes(searchName.toLowerCase())
    );
    return filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteUser = async (userId, firstName, lastName) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete user ${firstName} ${lastName}?`
    );

    if (confirmDelete) {
      try {
        await deleteUserMutation.mutateAsync(userId);
        queryClient.invalidateQueries("useGetUsers");
        setUsersData((prevUsersData) => ({
          ...prevUsersData,
          users: prevUsersData.users.map((user) =>
            user.id === userId ? { ...user, deleted: true } : user
          ),
        }));
      } catch (error) {
        console.error("Error deleting user: ", error);
      }
    }
  };

  const deleteUserMutation = useMutation((userId) => {
    return axios.post(
      `${config.apiBaseURL}/api/delete_user/${userId}`,
      {},
      {
        withCredentials: true,
      }
    );
  });

  const openRegisterModal = () => {
    setIsRegisterModalOpen(true);
  };

  const handleCloseAndInvalidateQuery = async () => {
    setIsRegisterModalOpen(false);
    await refetch();
    setCurrentPage(1); // Reset to first page when a new user is added
  };

  const EditIconWrapped = getSvgHoverIcon(EditIcon);
  const TrashIconWrapped = getSvgHoverIcon(TrashIcon);

  return (
    <div className={classNames("basic-section", "admin-table-wrap")}>
      <Container>
        <RegisterModal
          isOpen={isRegisterModalOpen}
          handleClose={() => handleCloseAndInvalidateQuery()}
        />
        <div className="search-container">
          <input
            className="search-container-input"
            type="text"
            placeholder="Search"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <ButtonWithIcon
            classNameIconOverWrite={"add-button-icon"}
            text="Add New User"
            onClick={openRegisterModal}
          />
        </div>
        <table className="admin-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Weight</th>
              <th>Supplement</th>
              <th>Effects Report</th>
              <th>Products Report</th>
              <th>Questionnaire</th>
              <th>Genetic File</th>
              <th>SNP</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {getCurrentPageUsers()?.map((table, index) => (
              <tr key={index}>
                <td>{`${table?.first_name} ${table?.last_name}`}</td>
                <td>{table.age}</td>
                <td>{table.weight}</td>
                <td>{table.supplement_type}</td>
                <td>
                  <button
                    className="admin-table-button"
                    onClick={() => handleSeeMore(table.id || "")}
                  >
                    See More
                  </button>
                </td>
                <td>
                  <Modal buttonStyle="admin-table-button" id={table.id} />
                </td>
                <td>
                  <Modal
                    buttonStyle="admin-table-button"
                    id={table.id}
                    isQuestionnaire={true}
                  />
                </td>
                <td>
                  <AdminGeneticUpload
                    userId={table.id}
                    setErrorSnackbarOpen={setErrorSnackbarOpen}
                    setErrorMessage={setErrorMessage}
                  />
                </td>
                <td>
                  <Modal
                    buttonStyle="admin-table-button"
                    id={table.id}
                    isSnpMapping={true}
                  />
                </td>
                <td>
                  <div className="admin-icons-cell">
                    <div
                      onClick={() => {
                        //edit func
                      }}
                    >
                      <EditIconWrapped />
                    </div>
                    <div
                      onClick={() =>
                        handleDeleteUser(
                          table.id,
                          table.first_name,
                          table.last_name
                        )
                      }
                    >
                      <TrashIconWrapped />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <LeftArrow
            onClick={() => {
              if (currentPage > 1) {
                handlePageClick(currentPage - 1);
              }
            }}
            stroke={getArrowStroke(currentPage === 1)}
          />
          <div className="pagination-numbers">
            {Array.from({ length: totalPages }, (_, index) => (
              <span
                key={index}
                className={`page-number ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                onClick={() => handlePageClick(index + 1)}
              >
                {index + 1}
              </span>
            ))}
          </div>
          <RightArrow
            onClick={() => {
              if (currentPage < totalPages) {
                handlePageClick(currentPage + 1);
              }
            }}
            stroke={getArrowStroke(currentPage === totalPages)}
          />
        </div>

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          onClose={() => setErrorSnackbarOpen(false)}
          sx={{ marginLeft: "200px" }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default AdminTable;
