import { Outlet, Navigate } from "react-router-dom";
import { useCurrentUser } from "../Auth/UseCurrentUser";
// import { currentUser } from "../Cards/mockData";

const PublicRoutes = () => {
  const { isLoading, error, data: currentUser } = useCurrentUser();
  const auth = currentUser?.authenticated;
  return auth ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoutes;
