import React, { useState, useEffect } from "react";
import Modal from "../../ui/Modal/Modal";
import axios from "axios";
import config from "../../config";
import { useQuery } from "react-query";
import LeftArrow from "../../assets/svgComponents/LeftArrow";
import RightArrow from "../../assets/svgComponents/RightArrow";
import classNames from "classnames";
import PlanHistory from "./PlanHistory";
import jsPDF from "jspdf";

const getArrowStroke = (flag) =>
  flag ? "rgba(142, 149, 169, .4)" : "rgba(142, 149, 169, 1)";

const formatDate = (value) => {
  if (!value) return "-";
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const fetchSubscribedUsers = async () => {
  const { data } = await axios.get(`${config.apiBaseURL}/admin/payment/users`, {
    withCredentials: true,
  });
  return data;
};

const downloadPostageLabel = (shippingDetails) => {
  const { address, name } = shippingDetails;

  const doc = new jsPDF();
  doc.setFontSize(40);
  doc.text(name, 20, 40);
  doc.text(`${address.line1}, ${address.city}`, 20, 80);
  if (address.line2) doc.text(address.line2, 20, 100);
  doc.text(`${address.postal_code}, ${address.country}`, 20, 120);

  doc.save(`${name.replace(" ", "_")}_Postage_Label.pdf`);
};

export const UserSubscriptionList = () => {
  const { data: adminData } = useQuery("subscribedUsers", fetchSubscribedUsers);
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [showPlanHistory, setShowPlanHistory] = useState({
    show: false,
    id: "",
  });
  const [usersData, setUsersData] = useState(null);

  useEffect(() => {
    if (adminData) {
      setUsersData(adminData);
    }
  }, [adminData]);

  const totalUsers = adminData?.filter((user) => !user.deleted)?.length || 0;
  const totalPages = Math.ceil(totalUsers / usersPerPage);

  const getCurrentPageUsers = () => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const filteredUsers = usersData?.filter((user) => {
      return `${user?.first_name} ${user?.last_name}`
        .toLowerCase()
        .includes(searchName.toLowerCase());
    });
    return filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);
  };

  return (
    <div className={classNames("LeftDiv", "admin-wrap")}>
      <h2 className="basic-page-header">Subscription list</h2>

      <div className={classNames("basic-section", "")}>
        <div>
          <div className="search-container">
            <input
              className="search-container-input"
              type="text"
              placeholder="Search"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
          <table className="admin-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Current Plan</th>
                <th>Amount / month</th>
                <th>Plan Start Date</th>
                <th>Plan End Date</th>
                <th>Plans History</th>
                <th>Postage Label</th>
              </tr>
            </thead>
            <tbody>
              {getCurrentPageUsers()?.map((table, index) => {
                const planStartDate = formatDate(
                  table?.subscription_start_date
                );
                const planEndDate = formatDate(table?.subscription_end_date);
                const amount = table?.subscription_plan?.amount
                  ? `£ ${table?.subscription_plan?.amount}`
                  : "-";
                return (
                  <tr key={table?.id || index}>
                    <td>{`${table?.first_name} ${table?.last_name}`}</td>
                    <td>{table?.subscription_plan?.title || "-"}</td>
                    <td>{amount}</td>
                    <td>{planStartDate}</td>
                    <td>{planEndDate}</td>
                    <td>
                      <button
                        className="admin-table-button"
                        onClick={() =>
                          setShowPlanHistory({ show: true, id: table?.id })
                        }
                      >
                        See More
                      </button>
                    </td>
                    <td>
                      <button
                        className="admin-table-button"
                        onClick={() =>
                          downloadPostageLabel(table?.shipping_details)
                        }
                      >
                        Download Label
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <LeftArrow
              onClick={() => {
                if (currentPage > 1) {
                  setCurrentPage(currentPage - 1);
                }
              }}
              stroke={getArrowStroke(currentPage === 1)}
            />
            <div className="pagination-numbers">
              {Array.from({ length: totalPages }, (_, index) => (
                <span
                  key={index}
                  className={`page-number ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </span>
              ))}
            </div>
            <RightArrow
              onClick={() => {
                if (currentPage < totalPages) {
                  setCurrentPage(currentPage + 1);
                }
              }}
              stroke={getArrowStroke(currentPage === totalPages)}
            />
          </div>

          {showPlanHistory?.show && (
            <Modal onClose={() => setShowPlanHistory(null)}>
              <PlanHistory userId={showPlanHistory.id} />
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};
