import React from "react";

interface ArrowProps {
  stroke: string;
  onClick: () => void;
  disabled: boolean;
}

const LeftArrow: React.FC<ArrowProps> = ({ stroke, onClick, disabled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    onClick={disabled ? undefined : onClick}
    style={{
      cursor: disabled ? "not-allowed" : "pointer",
      opacity: disabled ? 0.4 : 1,
    }}
  >
    <path
      d="M13 5.00012L8 10.0001L13 15.0001"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LeftArrow;
