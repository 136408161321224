import React, { useEffect, useState, useContext } from "react";
import { useIsHidden } from "../../hooks/useIsHidden";
import { Drawer } from "@mui/material";
import NavLinks from "./NavLinks";
import useResponsive from "../../hooks/useResponsive";
import NavBarMobileHeader from "./NavBarMobileHeader";
import PersonalSupplement from "../PersonalSupplement/PersonalSupplement";
import classNames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Context } from "../../helpers/Context";

const NavBarMobile: React.FC = () => {
  const { isHidden, handleHiddenToggle } = useIsHidden(true);

  const { setIsOpenPersonalSup, isOpenPersonalSup } = useContext(Context);

  const { isTinyMobile } = useResponsive();

  useEffect(() => {
    if (!isTinyMobile) {
      if (isOpenPersonalSup) {
        if (isHidden) {
          handleHiddenToggle();
        }
        setIsOpenPersonalSup(isOpenPersonalSup);
      }
    }
  }, [isOpenPersonalSup, isTinyMobile]);

  const onNotifClick = () => {
    console.log("here will be notification function");
  };

  return (
    <>
      <NavBarMobileHeader
        isDrawerOpened={!isHidden}
        onHiddenToggle={handleHiddenToggle}
        onNotifClick={onNotifClick}
      />
      <Drawer
        sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        anchor={isTinyMobile ? "top" : "right"}
        open={!isHidden}
        onClose={handleHiddenToggle}
      >
        <div
          className={classNames(
            "drawer-content-wrap",
            isOpenPersonalSup && !isTinyMobile && "drawer-content-wrap-wide"
          )}
        >
          <TransitionGroup>
            {isOpenPersonalSup && !isTinyMobile ? (
              <CSSTransition
                in={isOpenPersonalSup}
                timeout={300}
                classNames="fade"
                key="personal-supplement"
              >
                <PersonalSupplement
                  header={
                    <NavBarMobileHeader
                      isInsideDrawer={true}
                      onGoBack={() => setIsOpenPersonalSup(false)}
                      onHiddenToggle={() => {
                        handleHiddenToggle();
                        setIsOpenPersonalSup(false);
                      }}
                      onNotifClick={onNotifClick}
                      isDrawerOpened={!isHidden}
                      isPersonalSupplement={true}
                    />
                  }
                />
              </CSSTransition>
            ) : (
              <CSSTransition
                in={!isOpenPersonalSup}
                key="nav-links"
                timeout={300}
                classNames="fade"
              >
                <NavLinks
                  onPersonalSupplement={() => {
                    setIsOpenPersonalSup(true);
                    if (isTinyMobile) {
                      handleHiddenToggle();
                    }
                  }}
                  isDrawerOpened={!isHidden}
                  onHiddenToggle={handleHiddenToggle}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </Drawer>
    </>
  );
};

export default NavBarMobile;
