import classNames from "classnames";
import React from "react";

interface ButtonWithArrowProps {
  text: string;
  onClick: () => void;
  classNameOverWrite?: string;
  classNameIconOverWrite?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const ButtonWithIcon: React.FC<ButtonWithArrowProps> = ({
  text,
  onClick,
  classNameOverWrite,
  classNameIconOverWrite,
  disabled = false,
  type = "button",
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        classNameOverWrite ? classNameOverWrite : "",
        "button",
        "button-with-arrow"
      )}
    >
      <span>{text}</span>
      <div
        className={classNames(
          classNameIconOverWrite
            ? classNameIconOverWrite
            : "button-with-arrow-icon",
          "button-with-arrow-icon-base"
        )}
      />
    </button>
  );
};

export default ButtonWithIcon;
