import { useState } from "react";
import Table from "react-bootstrap/Table";
import "./admin.css";

const AdminTable = (props) => {
  const { tableData = [], isSnpMapping } = props;
  const [searchQuery, setSearchQuery] = useState("");

  if (isSnpMapping) {
    if (!tableData) {
      return <p>No snp mapping</p>;
    }
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };

    const filteredSnps = Object.entries?.(tableData)?.filter(
      ([rsid, genotype]) =>
        rsid?.toLowerCase()?.includes?.(searchQuery.toLowerCase())
    );

    return (
      <div className="snp-modal-container">
        <input
          type="text"
          placeholder="Search rsid..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="snp-search-input"
        />
        <div className="snp-modal-content">
          <div className="snp-category">
            <h3>Homozygous</h3>
            <ul>
              {filteredSnps
                .filter(([rsid, genotype]) => genotype[0] === genotype[1])
                .map(([rsid, genotype]) => (
                  <li key={rsid}>
                    <span className="snp-rsid">{rsid}</span>
                    <span className="snp-genotype">{genotype}</span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="snp-category">
            <h3>Heterozygous</h3>
            <ul>
              {filteredSnps
                .filter(([rsid, genotype]) => genotype[0] !== genotype[1])
                .map(([rsid, genotype]) => (
                  <li key={rsid}>
                    <span className="snp-rsid">{rsid}</span>
                    <span className="snp-genotype">{genotype}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  if (!tableData.length) {
    return <p>No product data</p>;
  }

  return (
    <div className="adminProductTableContainer">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Dosages</th>
            <th>Monthly</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((table, index) => {
            return (
              <tr key={index}>
                <td>{table.name}</td>
                <td>{`${table.dosage} ${table.measurement_unit}`}</td>
                <td>{`${table.dosage * 30} ${table.measurement_unit}`}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminTable;
