import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import HowToRead from "./components/HowToRead/HowToRead";
import Questionnaire from "./components/Questionnaire/Questionnaire";
import SignUp from "./components/Auth/SignUp";
import SignIn from "./components/Auth/SignIn";
import { QueryClient, QueryClientProvider } from "react-query";

import PrivateRoutes from "./components/routes/PrivateRoutes";
import PublicRoutes from "./components/routes/PublicRoutes";
import { ThemeProvider } from "@mui/material";
import ContextProvider from "./helpers/Context";
import { basicTheme } from "./helpers/themes";
import Admin from "./components/admin/Admin";
import Settings from "./components/Settings/Settings";
import Home from "./components/Home/Home";
import SessionTimeout from "./components/SessionTimeout";
import PasswordResetForm from "./components/resetPassword/PasswordResetForm";
import PaymentStatusPage from "./components/Subscription/PaymentStatus";
import { UserSubscriptionList } from "./components/admin/UserSubscriptionList";
const App = () => {
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={basicTheme}>
      <BrowserRouter>
        <div className="App">
          <QueryClientProvider client={queryClient}>
            <ContextProvider>
              <Routes>
                <Route element={<PublicRoutes />}>
                  <Route path="/sign-up" element={<SignUp />} />
                  <Route path="/sign-in" element={<SignIn />} />
                  <Route
                    path="/reset-password"
                    element={<PasswordResetForm />}
                  />
                </Route>

                <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/how_to_read" element={<HowToRead />} />
                  <Route path="/questionnaire" element={<Questionnaire />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route
                    path="/user-subscription"
                    element={<UserSubscriptionList />}
                  />
                </Route>
                <Route path="/payment-status" element={<PaymentStatusPage />} />
              </Routes>
              <SessionTimeout timeout={600} />
            </ContextProvider>
          </QueryClientProvider>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
