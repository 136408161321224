const ActiveDot = ({
  active,
  isEdit,
}: {
  active: boolean;
  isEdit?: boolean;
}) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7.5"
      cy="7.5"
      r="6"
      fill={isEdit ? "#6D57FC" : active ? "#6D57FC" : "#A7A9CA"}
      stroke="white"
      strokeWidth="3"
    />
  </svg>
);

export default ActiveDot;
