import React, { useState } from "react";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import Modal from "../../ui/Modal/Modal";
import axios from "axios";
import config from "../../config";
import { useMutation } from "react-query";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";
import SubscriptionPayment from "../Subscription/SubscriptionPayment";
import { Box, DialogContent } from "@mui/material";

interface FooterProps {
  helpButtonText: string;
  actionButtonText: string;
  products?: Array<{
    dosage: number;
    name: string;
    measurement_unit: string;
  }>;
  actionButtonDisabled?: boolean;
}

const Footer: React.FC<FooterProps> = ({
  helpButtonText,
  actionButtonText,
  actionButtonDisabled,
  products,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOrderClick = () => {
    // Commented code to send order as we are calling it after user purchase subscription plan
    // sendOrder();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // const { data: currentUser } = useCurrentUser();
  // const userId = currentUser?.user?.id;

  // const sendOrderEmail = async () => {
  //   return axios.post(
  //     `${config.apiBaseURL}/send_product_order`,
  //     { user_id: String(userId), products }, // Ensure user_id is a string
  //     {
  //       withCredentials: true,
  //     }
  //   );
  // };

  // const {
  //   mutate: sendOrder,
  //   isLoading,
  //   isSuccess,
  // } = useMutation(sendOrderEmail);

  return (
    <footer className="footer">
      <div
        style={{
          ...(actionButtonDisabled && { display: "flex", gap: "1rem" }),
        }}
        className={actionButtonDisabled ? "" : "footerButtonWrap"}
      >
        <button
          className="button footerHelpButton"
          onClick={() => (window.location.href = "mailto:info@myoform.io")}
        >
          {helpButtonText}
        </button>
        <ButtonWithIcon
          onClick={handleOrderClick}
          text={actionButtonText}
          disabled={actionButtonDisabled}
          {...(actionButtonDisabled && {
            classNameOverWrite: "footerHelpButton",
            classNameIconOverWrite: "display-none",
          })}
        />
      </div>
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <DialogContent sx={{ margin: "1rem", height: "90vh" }}>
            <Box>
              <SubscriptionPayment />
            </Box>
          </DialogContent>
        </Modal>
      )}
    </footer>
  );
};

export default Footer;
