import classNames from "classnames";
import AuthFooter from "./AuthFooter";
import AuthHeader from "./AuthHeader";
import { useAlternativeBackground } from "./hooks";
import { useCallback, useState } from "react";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import { alternativeBackgrounds } from "./constants";
import useResponsive from "../../hooks/useResponsive";
import axios from "axios";
import config from "../../config";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

interface SignUpProps {}

const SignUp: React.FC<SignUpProps> = ({}) => {
  const { isDesktop, isMobile } = useResponsive();
  const alternativeBackground = useAlternativeBackground(
    alternativeBackgrounds
  );

  const [isFormOpened, setIsFormOpened] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const onCreateAccountClick = useCallback(() => {
    setIsFormOpened(true);
  }, [isFormOpened]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = [];
    if (!formData.first_name) newErrors.push("First name is required.");
    if (!formData.last_name) newErrors.push("Last name is required.");
    if (!formData.email) {
      newErrors.push("Email is required.");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.push("Email address is invalid.");
    }
    if (formData.password.length < 7)
      newErrors.push("Password must be at least 7 characters long.");
    if (formData.password !== formData.confirm_password)
      newErrors.push("Passwords do not match.");

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const mutation = useMutation(
    (newUser: typeof formData) =>
      axios.post(`${config.apiBaseURL}/auth/register`, newUser, {
        withCredentials: true,
      }),
    {
      onSuccess: () => {
        setSuccessMessage("Account created successfully!");
        navigate("/");
        setErrorMessage(null);
      },
      onError: (error: any) => {
        if (axios.isAxiosError(error)) {
          setErrorMessage(
            error.response?.data?.message ||
              "An error occurred during registration."
          );
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
        setSuccessMessage(null);
      },
    }
  );

  const handleSubmit = (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    if (validateForm()) {
      mutation.mutate(formData);
    }
  };

  return (
    <div
      className={classNames(
        "sign-up-wrap",
        isDesktop
          ? alternativeBackground
          : isFormOpened
          ? "sign-up-wrap-hide-gradient"
          : alternativeBackgrounds[0],
        isFormOpened && isMobile && "no-content"
      )}
    >
      <AuthHeader toSignUp={true} />
      <div
        className={classNames(
          "sign-up-content",
          isFormOpened && "sign-up-wrap-with-form"
        )}
      >
        <div className="sign-up-header">
          {!isFormOpened ? (
            <div className={classNames("sign-up-logo", "auth-mobile-only")} />
          ) : (
            <div className="sign-in-logo" />
          )}
          {!isFormOpened && (
            <h1>
              Perform to your
              <br />
              potential.
            </h1>
          )}
        </div>
        {isFormOpened && (
          <div className={classNames("sign-up-form-wrap")}>
            <h3 className="sign-up-form-title">Create Account</h3>
            <span className="sign-up-form-subtitle">
              Let's start performing
            </span>
            <form className="sign-up-form" onSubmit={handleSubmit}>
              <fieldset className="sign-up-form-names">
                <input
                  type="text"
                  name="first_name"
                  placeholder="First name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
              </fieldset>
              <fieldset className="sign-up-form-credentials">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <input
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm password"
                  value={formData.confirm_password}
                  onChange={handleInputChange}
                />
              </fieldset>
              {errors.length > 0 && (
                <div className="sign-up-errors">
                  {errors.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              )}
              {successMessage && (
                <div className="sign-up-success">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="sign-up-error">{errorMessage}</div>
              )}
              <ButtonWithIcon
                classNameOverWrite={classNames(
                  "auth-desktop-only",
                  "auth-btn-sign-up"
                )}
                text="Sign Up"
                onClick={handleSubmit}
              />
            </form>
          </div>
        )}

        {!isFormOpened && (
          <button
            className={classNames(
              "button",
              "auth-btn",
              "auth-create-acc-btn",
              "auth-desktop-only"
            )}
            onClick={onCreateAccountClick}
          >
            Create Account
          </button>
        )}
        <AuthFooter
          isFormOpened={isFormOpened}
          buttonStyleOverwrite={isFormOpened ? "auth-btn-accent" : ""}
          onButtonClick={isFormOpened ? handleSubmit : onCreateAccountClick}
          hideMainButton={true}
          toSignUp={true}
          textOverwrite={isFormOpened ? "Sign Up" : ""}
        />
      </div>
    </div>
  );
};

export default SignUp;
