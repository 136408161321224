import React from "react";
import { useIsHidden } from "../../hooks/useIsHidden";
import ExpandButton from "../ExpandButton/ExpandButton";
import VitaminItem from "./VitaminItem";

export interface VitaminItemType {
  dosage: number;
  justification: string;
  links: string[];
  measurement_unit: string;
  name: string;
}

interface VitaminsListProps {
  vitaminsList: Array<VitaminItemType>;
  isHideButton?: boolean;
}

const VitaminsList: React.FC<VitaminsListProps> = ({
  vitaminsList,
  isHideButton = false,
}) => {
  const { isHidden, handleHiddenToggle } = useIsHidden();

  return (
    <div className="vitaminListWrap">
      {!isHideButton && (
        <ExpandButton onClick={handleHiddenToggle} isHidden={isHidden} />
      )}
      {!isHidden && (
        <div className="vitaminsListList">
          {vitaminsList.map((vitamin) => (
            <VitaminItem vitamin={vitamin} />
          ))}
        </div>
      )}
    </div>
  );
};

export default VitaminsList;
