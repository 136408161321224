import * as yup from "yup";

export const validationSchema = {
  generalQuestions: yup.object().shape({
    gender: yup.string().required("Gender is required"),
    date_of_birth: yup.string().required("Age is required"),
    origin: yup.array().required("Origin is required"),
    ethnicity: yup.string().required("Ethnicity is required"),
    height: yup
      .number()
      .typeError("Height must be a number")
      .required("Height is required")
      .positive("Height must be a positive number")
      .integer("Height must be an integer"),
    weight: yup
      .number()
      .typeError("Weight must be a number")
      .required("Weight is required")
      .positive("Weight must be a positive number")
      .integer("Weight must be an integer"),
  }),

  nutrition: yup.object().shape({
    dietaryConsiderations: yup
      .string()
      .required("Dietary considerations are required"),
    allergiesOrIntolerances: yup
      .string()
      .required("Please specify if you have any allergies or intolerances"),
    allergiesOrIntolerancesType: yup.string().when("allergiesOrIntolerances", {
      is: (value) => value === "Yes",
      then: (schema) =>
        schema
          .notOneOf(
            ["None of These"],
            "If you have allergies or intolerances, please select a specific type"
          )
          .required("Please specify the type of allergy or intolerance"),
      otherwise: (schema) => schema.nullable(),
    }),
    breakfast: yup.string().required("Please describe your typical breakfast"),
    lunch: yup.string().required("Please describe your typical lunch"),
    dinner: yup.string().required("Please describe your typical dinner"),
    snacks: yup
      .string()
      .required(
        "Please describe any snacks/other food consumed throughout the day"
      ),
    caffeinatedDrinks: yup
      .string()
      .required("Caffeinated drinks information is required"),
    personalizedNutrition: yup
      .string()
      .required("Personalized nutrition preference is required"),
    supplements: yup.string().required("Supplements information is required"),
    supplementsDescription: yup.string().when("supplements", {
      is: (value) => value !== "None",
      then: (schema) =>
        schema.required(
          "Supplements description is required when supplements are selected"
        ),
      otherwise: (schema) => schema.nullable(),
    }),
    caloricIntake: yup
      .string()
      .required("Caloric intake information is required"),
    weightGoal: yup.string().required("Weight goal information is required"),
  }),

  training: yup.object().shape({
    weeklyExerciseHours: yup
      .string()
      .required("Weekly exercise hours is required"),
    trainingType: yup.array().required("Training type is required"),
    trainingTypeOther: yup.string().when("trainingType", {
      is: (value) => value && value.includes("Other"),
      then: (schema) => schema.required("Please specify the training type"),
      otherwise: (schema) => schema.nullable(),
    }),
    improvementGoals: yup.array().required("Improvement goals are required"),
    consumeSupplementsDuringTraining: yup
      .string()
      .required("Supplements consumption during training is required"),
    supplement_type: yup
      .string()
      .required("Exercise supplement needs are required"),
    sunExposure: yup.string().required("Sun exposure information is required"),
    alcoholFrequency: yup.string().required("Alcohol frequency is required"),
    smokingHabit: yup.string().required("Smoking habit is required"),
    focusIssues: yup.string().required("Focus issues information is required"),
    forgetfulness: yup
      .string()
      .required("Forgetfulness information is required"),
    currentSymptoms: yup
      .array()
      .required("Current symptoms information is required"),
  }),

  sleepAndIllness: yup.object().shape({
    sleepHours: yup.string().required("Sleep hours information is required"),
    commonIllnessFrequency: yup
      .string()
      .required("Common illness frequency is required"),
    commonIllnessRecoveryTime: yup
      .string()
      .required("Common illness recovery time is required"),
    antibioticsFrequency: yup
      .string()
      .required("Antibiotics frequency is required"),
    prescriptionMedicine: yup
      .string()
      .required("Prescription medicine information is required"),
    preExistingConditions: yup
      .string()
      .required("Pre-existing conditions information is required"),
    preExistingConditionsDescription: yup
      .string()
      .when("preExistingConditions", {
        is: "Yes",
        then: (schema) =>
          schema.required("Pre-existing conditions description is required"),
        otherwise: (schema) => schema.nullable(),
      }),
  }),
};
