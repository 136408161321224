import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import axios from "axios";
import config from "../config";
import { SORT } from "./variables";

interface ContextType {
  sortOrder: string;
  report: Report;
  setSortOrder: (value: string) => void;
  isExpandedInfoHidden: boolean;
  handleToggleExpandable: (flag?: boolean | undefined) => void;
  setIsOpenPersonalSup: (flag: boolean | undefined) => void;
  isOpenPersonalSup: boolean;
  setUserId: (userId: string) => void;
  calculateReport: () => void;
}

export const Context = createContext<ContextType>({
  sortOrder: SORT.ALPHA,
  report: { effects: [], products: [] },
  setSortOrder: () => {},
  isExpandedInfoHidden: true,
  isOpenPersonalSup: false,
  setIsOpenPersonalSup: () => {},
  handleToggleExpandable: () => {},
  setUserId: () => {},
  calculateReport: () => {},
});

interface Report {
  effects: Array<any>;
  products?: Array<any>;
}

interface ContextProviderProps {
  children: ReactNode;
}

const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const [isExpandedInfoHidden, setIsExpandedInfoHidden] = useState(true);
  const [sortOrder, setSortOrder] = useState<string>(SORT.ALPHA);
  const [isOpenPersonalSup, setIsOpenPersonalSup] = useState(false);
  const [report, setReport] = useState<Report>({ effects: [], products: [] });
  const [userId, setUserId] = useState<string | null>(null);

  const handleToggleExpandable = useCallback(
    (flag?: boolean) => {
      setIsExpandedInfoHidden(
        flag !== undefined ? flag : !isExpandedInfoHidden
      );
    },
    [isExpandedInfoHidden]
  );

  const handleSetIsOpenPersonalSup = useCallback((flag?: boolean) => {
    setIsOpenPersonalSup(flag ?? false);
  }, []);

  const calculateReport = useCallback(() => {
    axios
      .post(`${config.apiBaseURL}/calculate`, null, { withCredentials: true })
      .then((response) => {
        setReport(response.data);
      })
      .catch((error) => {
        console.error("Error calculating report:", error);
      });
  }, []);

  useEffect(() => {
    if (userId) {
      axios
        .get(`${config.apiBaseURL}/users/${userId}`, { withCredentials: true })
        .then((response) => {
          const selectedUser = response.data;
          const sortedEffects = selectedUser.effects;
          setReport({
            effects: sortedEffects,
            products: selectedUser.products || [],
          });
        })
        .catch((error) => {
          console.error("Error fetching user report:", error);
        });
    }
  }, [userId]);
  return (
    <Context.Provider
      value={{
        sortOrder,
        setSortOrder,
        isExpandedInfoHidden,
        handleToggleExpandable,
        isOpenPersonalSup,
        setIsOpenPersonalSup: handleSetIsOpenPersonalSup,
        report,
        setUserId,
        calculateReport,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
